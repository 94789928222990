import "../popoutStyle.css"
import "./historyStyle.css"
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { hide, setHistoryDay, setHistoryMonth, setHistoryYear } from "../popoutSlice";
import { loadBoard, nextTutorialState } from "../../fullpageSlice";
import { millisecondsToString } from "../../timer/timerSlice";
import { TutorialStep } from "../../tutorial/tutorialSlice";

export function HistoryContent() {
    const menuSelection = useSelector((state: RootState) => state.popout.menuSelection);
    const historyDict = useSelector((state: RootState) => state.popout.history);
    const selectedYear = useSelector((state: RootState) => state.popout.selectedYear);
    const selectedMonth = useSelector((state: RootState) => state.popout.selectedMonth);
    const selectedDay = useSelector((state: RootState) => state.popout.selectedDay);
    const recentGames = useSelector((state: RootState) => state.fullpage.recentPlayedGames);
    const tutorialStep = useSelector((state: RootState) => state.fullpage.tutorial.currentStep);

    const dispatch = useDispatch<AppDispatch>()

    if (menuSelection !== "history")
        return (<></>);

    // START DAY SELECTION STUFF ///////////////////////////////
    let ctr = 0;
    //{year: {month: { day: GridID }}}
    let dictKeys: Record<string,Record<string,Record<string, string>>> = {}

    let optionsYear: Array<string> = [];
    let useYear = "";
    let useMonth = "";
    let useDay = "";

    let now = new Date();
    const offset = now.getTimezoneOffset();
    let local = new Date(now.getTime() - offset*60*1000);
    let todayString = local.toISOString().split('T')[0];

    for(let k in historyDict) {
        //don't show things that haven't technically started on the next day yet
        if(k > todayString)
            continue;
        let yyyyMMdd = k.split("-");
        if(!(yyyyMMdd[0] in dictKeys)) {
            dictKeys[yyyyMMdd[0]] = {}
            optionsYear.push(yyyyMMdd[0]);
        }
        if(!(yyyyMMdd[1] in dictKeys[yyyyMMdd[0]]))
            dictKeys[yyyyMMdd[0]][yyyyMMdd[1]] = {}

        dictKeys[yyyyMMdd[0]][yyyyMMdd[1]][yyyyMMdd[2]] = historyDict[k];
        ctr++;
    }

    optionsYear = optionsYear.sort().reverse();
    //first get the year
    if(!(selectedYear in dictKeys)) {
        //get the latest year
        useYear = optionsYear[0];
    }
    else
        useYear = selectedYear;


    let possibleMonths = dictKeys[useYear];
    let optionsMonth: Array<string> = [];
    for(let month in possibleMonths)
        optionsMonth.push(month);
    optionsMonth = optionsMonth.sort().reverse();

    if(!(selectedMonth in possibleMonths)) {
        //get the latest year
        useMonth = optionsMonth[0];
    }
    else
        useMonth = selectedMonth;

    let possibleDays = possibleMonths[useMonth];
    let optionsDays: Array<string> = [];
    for(let day in possibleDays)
        optionsDays.push(day);
    optionsDays.sort().reverse();

    if(!(selectedDay in possibleDays)) {
        //get the latest day... today.
        useDay = optionsDays[0];
    }
    else
        useDay = selectedDay;


    let selectYear = []
    let selectMonth = []
    let selectDay = []

    for(let i=0; i<optionsYear.length; i++) {
        let yr=optionsYear[i];
        selectYear.push((
            <option
                key={yr}
                value={yr}
            >{yr}</option>
        ))
    }

    for(let i=0; i<optionsMonth.length; i++) {
        let month=optionsMonth[i];
        selectMonth.push((
            <option
                key={month}
                value={month}
            >{month}</option>
        ))
    }

    for(let i=0; i<optionsDays.length; i++) {
        let day=optionsDays[i];
        selectDay.push((
            <option
                key={day}
                value={day}
            >{day}</option>
        ))
    }

    //Now figure out the saved history/////////////////////////////////

    let allGamesPlayed=[];
    for(let i=0; i<recentGames.length; i++) {
        let gamedPlayed = recentGames[i];
        /*
        <th>Date</th>
        <th>Time Spent</th>
        <th>Time Score</th>
        <th>Hint Score</th>
        <th>Word Score</th>
        <th>Total Score</th>
        */
        allGamesPlayed.push((
            <tr key={`agp_tr_${i}`} className="tight">
                <td key={`agp_td_${i}_0`} className="tight playedTd">{gamedPlayed.day} </td>
                <td key={`agp_td_${i}_5`} className="tight playedTd bold">{gamedPlayed.totalScore}</td>
                <td key={`agp_td_${i}_2`} className="tight playedTd">{gamedPlayed.timerScore} ({millisecondsToString(gamedPlayed.timePlayedMs, 2)})</td>
                <td key={`agp_td_${i}_3`} className="tight playedTd">{gamedPlayed.hintsGiven}</td>
                <td key={`agp_td_${i}_4`} className="tight playedTd">{gamedPlayed.wordsAttempted}</td>

            </tr>
        ))
    }
    if(allGamesPlayed.length === 0) {
        allGamesPlayed.push((
            <tr key={`agp_tr_0`} className="tight">
                <td key={`agp_td_0_0`} colSpan={5} className="tight playedTd">No Games Finished Yet</td>
            </tr>
        ))
    }

    return (
        <>
        <span className="puzzleCount">{ctr} puzzles and counting!</span>
        <hr />
        <table className="historyTableChoice"><tbody>
            <tr><th className="tdHeader">Year</th><th className="tdHeader">Month</th><th className="tdHeader">Day</th></tr>
            <tr>
                <td className="tdSelect">
                    <select key="year" value={useYear} onChange={
                        (e) => {
                            let year = e.target.value;
                            dispatch(setHistoryYear(year));
                        }
                    }>
                        {selectYear}
                    </select>
                </td>
                <td className="tdSelect">
                    <select key="month" value={useMonth} onChange={
                        (e) => {
                            let month = e.target.value;
                            dispatch(setHistoryMonth(month));
                        }
                    }>
                        {selectMonth}
                    </select>
                </td>
                <td className="tdSelect">
                    <select key="day" value={useDay} onChange={
                        (e) => {
                            let day = e.target.value;
                            dispatch(setHistoryDay(day));
                        }
                    }>
                        {selectDay}
                    </select>
                </td>
            </tr>
            <tr>
                <td colSpan={3} className="tdButton">
                    <button className="buttonLoad" onClick={(e) => {
                        if(tutorialStep === TutorialStep.DONE) {
                            let boardSelection = `${useYear}-${useMonth}-${useDay}`
                            dispatch(loadBoard(boardSelection))
                            dispatch(hide(tutorialStep));
                        }

                    }}>Load</button>
                </td>
            </tr>
            <tr>
                <td colSpan={3} className="tdButton">
                    <button className="buttonLoadToday" onClick={(e) => {
                        if(tutorialStep === TutorialStep.LOAD_TODAY || tutorialStep === TutorialStep.DONE) {
                            if(tutorialStep === TutorialStep.LOAD_TODAY)
                                dispatch(nextTutorialState());  //move to done and save as done.
                            let gridToLoad = local.toISOString().split('T')[0];
                            dispatch(loadBoard(gridToLoad))
                            dispatch(hide(tutorialStep));
                        }

                    }}>Load Today</button>
                </td>
            </tr>
        </tbody></table>
        <hr />
        <table className="historyTableChoice tight"><tbody className="tight">
            <tr className="tight">
                <th className="tight playedTd">Date</th>
                <th className="tight playedTd">Total</th>
                <th className="tight playedTd">Time</th>
                <th className="tight playedTd">Hint</th>
                <th className="tight playedTd">Word</th>

            </tr>
            {allGamesPlayed}
        </tbody></table>
        </>
    )
}
/*
previously in the load buttons
let timerId = setInterval(() => {dispatch(updateTimer())}, 1000)
dispatch(setTimerId(timerId));
*/