import { FullState } from "../fullpageSlice";
import { CellConditions } from "../grid/cell/cellSlice";
import { GridState } from "../grid/gridSlice";
import { savePlayHistory } from "../popout/history/historySlice";
import { SolveLevel } from "../solution";
import { TutorialStep } from "../tutorial/tutorialSlice";
import { HintState } from "../userInput/hints/hintSlice";
import { clearWordTypedResetGrid } from "../userInput/userInputSlice";
import { WordLocations } from "../words";

export function getWordScore(words: Array<WordLocations>): number {
    let wordScore = 0;
    for(let i=0; i<words.length; i++) {
        let wd = words[i];
        switch(wd.solveLevel) {
            case SolveLevel.hidden:
            case SolveLevel.clue: // shouldn't happen. This was usurped by hints
            case SolveLevel.suggested: //shouldn't happen - this is only really used in arrays to show the user extra places
                continue;
            case SolveLevel.confirmed:  //the word came from a hint
            case SolveLevel.doesNotExist:   //hint provided, potentially not cleared
            case SolveLevel.guessed:    //majority of their entries
            case SolveLevel.guessLetter:
            case SolveLevel.ignore:     //hint provided, but now we don't show the hint anymore
            case SolveLevel.wrongLocation:  //hint provided, potentially not cleared?
            default:
                break;
        }
        wordScore++;
    }
    return wordScore;
}

export function getHintScore(hints: Array<HintState>): number {
    return hints.length;
}

export function getTimerScore(timeSpentMs: number): number {
    return Math.floor(timeSpentMs/30000); //1 point for every 30 seconds
}

export function processWin(state: FullState, savePlayHist: boolean) {
    if(!testWin(state.userDisplay, state.finalSolution))
        if(!testAllSolutionsWin(state.userDisplay, state.finalSolution, state.lettersAvailable, state.allIndexedWords))
            return;

    /*
    if(state.timers.timerId !== null) {
        clearInterval(state.timers.timerId);
        state.timers.timerId = null;
    }
    */

    state.win = true;

    for(let i=0;i<state.userDisplay.words.length; i++) {
        let wd = state.userDisplay.words[i];
        if(wd.solveLevel === SolveLevel.guessed || wd.solveLevel === SolveLevel.guessLetter)
            wd.solveLevel = SolveLevel.confirmed;
    }
    clearWordTypedResetGrid(state);
    state.wordTyped = "YOU WIN!!"
    state.puzzleEndTime = Date.now();
    if(savePlayHist)
        savePlayHistory(state.recentPlayedGames, state)
    if(state.tutorial.currentStep !== TutorialStep.DONE)
        state.tutorial.currentStep = TutorialStep.OPEN_MENU_END;

}


export function testWin(gridState: GridState, solution: GridState) {
    for(let row=0; row<solution.numRows; row++) {
        for(let col=0; col<solution.numCols; col++) {
            let solCel = solution.cells[row][col];
            if(solCel.condition === CellConditions.blocked)
                continue
            let gridCell = gridState.cells[row][col];
            if(solCel.text?.toLowerCase() !== gridCell.text?.toLowerCase())
                return false;
            if(!(gridCell.condition === CellConditions.confirmed || gridCell.condition === CellConditions.filled))
                return false;
        }
    }
    return true;
}

function canTestAgainstLetter(condition: CellConditions): boolean {
    switch(condition) {
        case CellConditions.blank:
        case CellConditions.hideSelected:
        case CellConditions.incorrect:
        case CellConditions.lettersAvailable:
        case CellConditions.suggestBad:
        case CellConditions.suggested:
        case CellConditions.blocked:
            return false;
        case CellConditions.confirmed:
        case CellConditions.filled:
        case CellConditions.selected:
        default:
            break;
    }
    return true;
}

function winTestIsUniqueLetter(gridState: GridState, row: number, col: number, availableLetters: string) {
    switch(gridState.cells[row][col].condition) {
        case CellConditions.blank:
        case CellConditions.hideSelected:
        case CellConditions.incorrect:
        case CellConditions.lettersAvailable:
        case CellConditions.suggestBad:
        case CellConditions.suggested:
            return false;
        case CellConditions.blocked:
        case CellConditions.confirmed:
            return true;
        case CellConditions.filled:
        case CellConditions.selected:
        default:
            break;
    }
    let lettersUsed = ""
    for(let r=0; r<gridState.numRows; r++) {
        if(r===row)
            continue;
        let cell = gridState.cells[r][col];
        if(!canTestAgainstLetter(cell.condition))
            continue;
        lettersUsed += cell.text?.toUpperCase();
    }
    for(let c=0; c<gridState.numCols; c++) {
        if(c===col)
            continue;
        let cell = gridState.cells[row][c];
        if(!canTestAgainstLetter(cell.condition))
            continue;
        lettersUsed += cell.text?.toUpperCase();
    }
    let testLetter = (gridState.cells[row][col].text || "").toUpperCase();
    if(lettersUsed.includes(testLetter))
        return false;

    if(!availableLetters.includes(testLetter))
        return false;

    return true;
}

function testHasWord(
    word: string,
    allWords: Record<string, Record<number, Array<string>>>
): boolean {
    if(word.length <= 1)
        return true;

    let possibleWords = allWords[word[0]][word.length];
    return (possibleWords.includes(word));
}

export function testAllSolutionsWin(
    gridState: GridState,
    solution: GridState,
    availableLetters: string,
    allWords: Record<string, Record<number, Array<string>>>
) {
    let goodLetters = availableLetters.toUpperCase();

    for(let row=0; row<solution.numRows; row++) {
        for(let col=0; col<solution.numCols; col++) {
            let solCel = solution.cells[row][col];
            if(solCel.condition === CellConditions.blocked)
                continue
            if(!winTestIsUniqueLetter(gridState, row, col, goodLetters))
                return false;
        }
    }
    //at this point, all letters are good. unique and in the correct things
    //now let's test for everything being words
    for(let row=0; row<gridState.numRows; row++) {
        let horizontalWord = "";
        for(let col=0; col<gridState.numCols; col++) {
            let cell = gridState.cells[row][col];
            if(cell.condition === CellConditions.blocked) {
                if(!testHasWord(horizontalWord, allWords))
                    return false;
                horizontalWord = "";
                continue;
            }
            horizontalWord += cell.text?.toUpperCase();
        }
        if(!testHasWord(horizontalWord, allWords))
            return false;
    }


    for(let col=0; col<gridState.numCols; col++) {
        let verticalWord = "";
        for(let row=0; row<gridState.numRows; row++) {
            let cell = gridState.cells[row][col];
            if(cell.condition === CellConditions.blocked) {
                if(!testHasWord(verticalWord, allWords))
                    return false;
                verticalWord = "";
                continue;
            }
            verticalWord += cell.text?.toUpperCase();
        }
        if(!testHasWord(verticalWord, allWords))
            return false;
    }

    return true;

}