import { useDispatch, useSelector } from 'react-redux';
import './cellStyle.css'
import { AppDispatch, RootState } from '../../../app/store';
import { CellConditions } from './cellSlice';
import { gridCellClick } from '../../fullpageSlice';

export type CellNewProps = {
    row: number;
    col: number
}

export function Cell(props: CellNewProps) {
    const gridCellState = useSelector((state: RootState) => state.fullpage.userDisplay.cells[props.row][props.col])
    const win = useSelector((state: RootState) => state.fullpage.win)
    const dispatch = useDispatch<AppDispatch>()


    const getClasses = () => {
        let styles = ['gridCell'];
        switch (gridCellState.condition) {
            // @ts-ignore
            case CellConditions.lettersAvailable: // @ts-ignore
                styles.push('lettersAvailable') // specifically no break
                // @ts-ignore
            case CellConditions.blank:
            case CellConditions.filled:
                styles.push('standardFill');
                break;
            case CellConditions.confirmed:
                styles.push('confirmed');
                break;
            case CellConditions.suggested:
                styles.push('suggested')
                break;
            case CellConditions.blocked:
                styles.push('locked');
                if (win)
                    styles.push('rotateObject');
                break;
            case CellConditions.incorrect:
            case CellConditions.suggestBad:
                styles.push('conflict');
                break;
            case CellConditions.selected:
            case CellConditions.hideSelected:
                styles.push('selected')
                break;
        }
        return styles.join(" ")
    }

    const getVAlign = () => {
        switch (gridCellState.condition) {
            case CellConditions.lettersAvailable:
                return "valignTop";
            case CellConditions.blank:
            case CellConditions.blocked:
            case CellConditions.hideSelected:
                return "hideText";
            case CellConditions.confirmed:
            case CellConditions.filled:
            case CellConditions.incorrect:
            case CellConditions.suggested:
            case CellConditions.selected:
            default:
                return "valignMiddle";
        }
    }

    return (
        <td className="tableTight" onClick={(e) => {
            dispatch(gridCellClick({row: props.row, col: props.col}))
        }}>
            <div className={getClasses()}>
                <span className={getVAlign()}>{(gridCellState.text || '').toUpperCase()}</span>
            </div>
        </td>
    )
}