import { useDispatch, useSelector } from 'react-redux'
import './typeButtonsStyle.css'

import { addLetterWordType, backLetter, clearWordTyped } from '../../fullpageSlice';
import { RootState } from '../../../app/store';

export function TypeButtons() {
    const fullState = useSelector((state: RootState) => state.fullpage)
    const dispatch = useDispatch();

    let buttons = []
    for(let i=0; i<fullState.typeButtons.length; i++) {
        let btn = fullState.typeButtons[i];
        let classes = btn.enabled ? "EntryButton EntryActive" : "EntryButton EntryDisabled"
        buttons.push((
            <button
                key={btn.letter}
                disabled={!btn.enabled}
                className={classes}
                onClick={(e) => {
                    dispatch(addLetterWordType(i));
                }}
                onDoubleClick={(e)=>{dispatch(addLetterWordType(i))}}
                onTouchEnd={(e)=>{dispatch(addLetterWordType(i))}}
            >{btn.letter.toUpperCase()}</button>
        ))
    }

    buttons.push((
        <button
            key="btnClear"
            className='ClearButton ClearActive'
            onClick={(e) => {
                dispatch(clearWordTyped())
            }}
        >*</button>
    ))

    let disableBack = true;
    let backClasses = "ClearButton ClearDisable"
    if(fullState.wordTyped ||
        fullState.selectedCell !== null ||
        fullState.selectedWordIndex >= 0
    ) {
        disableBack = false;
        backClasses = "ClearButton ClearActive"
    }


    buttons.push((
        <button
            key="btnBack"
            className={backClasses}
            disabled={disableBack}
            onClick={(e) => {
                dispatch(backLetter())
            }}
        >&lt;</button>
    ))

    return (
        <div className='EntryButtonDiv'>
        {buttons}
        </div>
    )
}