import './popoutStyle.css'
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

export function SummaryContent() {
    const menuSelection = useSelector((state: RootState) => state.popout.menuSelection);

    if (menuSelection !== "summary")
        return (<></>);

    return (
        <>
        <i>Alphique</i> is a game where cross word puzzles meet sudoku!
        Fill in all the letters for the puzzle correctly to win!
        <hr />
        Theoretically, a new puzzle will be made available daily. Everyone will
        receive the same puzzle, so you can compete with friends and family.
       <hr />
        </>
    )
}