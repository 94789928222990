import { useSelector } from 'react-redux'
import './gridStyle.css'
import './cell/cellStyle.css'
import { RootState } from '../../app/store'
import { Cell } from './cell/cell';

export function Grid() {
    const gridState = useSelector((state: RootState) => state.fullpage.userDisplay)

    let rows = [];
    for(let i=0; i<gridState.numRows; i++) {
        let cells = [];
        for(let j=0; j<gridState.numCols; j++) {
          let key = `${i}_${j}`;
          let cell = (
            <Cell key={key} row={i} col={j} />
          );
          cells.push(cell);
        }
        //let trKey=`row_${i}`;
        let row = (
          <tr key={`row_${i}`} className='tableTight'>
            {cells}
          </tr>
        );
        rows.push(row);
      }

      return (
          <div className="grid">
              <table className='tableTight tableWidth gridDivCenter'><tbody className='tableTight'>
                {rows}
              </tbody></table>
          </div>
        )
}