import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loadHistoryOptions } from "./history/historySlice";
import { isStateAllowNextTutorialStep, isTutorialActionAllowed, TutorialStep } from "../tutorial/tutorialSlice";
import { SwitchTabInput } from "./menuSelect/menuSelect";

export type PopoutState = {
    hidden: boolean,
    menuSelection: "rules" | "hints" | "howtoplay" | "development" | "scoring" | "summary" | "history" | "defn",
    history: Record<string, string> // 2024-08-15: UUID
    selectedYear: string
    selectedMonth: string
    selectedDay: string
}

const initPopoutState: PopoutState = {
    hidden: true,
    menuSelection: "summary",
    history: await fetch('./history/lookup.json')
        .then( res=>res.json()),
    selectedYear: "",
    selectedMonth: "",
    selectedDay: "",
}



export const popoutSlice = createSlice({
    name: 'popout',
    initialState: initPopoutState,
    reducers: {
        hide: (state: PopoutState, action: PayloadAction<TutorialStep>) => {
            if(!isTutorialActionAllowed(action.payload, "toggleMenu", "hide"))
                return;
            state.hidden = true;
            if(isStateAllowNextTutorialStep(action.payload, "hide"))
                return;
        },
        show: (state: PopoutState, action: PayloadAction<TutorialStep>) => {
            if(!isTutorialActionAllowed(action.payload, "toggleMenu", "show"))
                return;
            state.hidden = false;
            if(isStateAllowNextTutorialStep(action.payload, "show"))
                return;
        },
        toggle: (state: PopoutState, action: PayloadAction<TutorialStep>) => {
            let v = state.hidden ? "show":"hide"; //if it's hidden, the action is show
            if(!isTutorialActionAllowed(action.payload, "toggleMenu", v))
                return;
            state.hidden = !state.hidden;
            if(isStateAllowNextTutorialStep(action.payload, v))
                return;
        },
        switchTab: (state: PopoutState, action: PayloadAction<SwitchTabInput>) => {
            if(!isTutorialActionAllowed(action.payload.tutorialStep, "switchTab", action.payload.tab))
                return;
            state.menuSelection = action.payload.tab;
            if(isStateAllowNextTutorialStep(action.payload.tutorialStep, action.payload.tab))
                return;
        },
        setHistoryYear: (state: PopoutState, action: PayloadAction<string>) => {
            state.selectedYear = action.payload;
        },
        setHistoryMonth: (state: PopoutState, action: PayloadAction<string>) => {
            state.selectedMonth = action.payload;
        },
        setHistoryDay: (state: PopoutState, action: PayloadAction<string>) => {
            state.selectedDay = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadHistoryOptions.pending, (state,action) => {})
        builder.addCase(loadHistoryOptions.fulfilled, (state: PopoutState, action: PayloadAction<Record<string,string>>) => {
            state.history = {...state.history, ...action.payload}
        })
    },
})

export const {hide, show, toggle, switchTab, setHistoryYear, setHistoryMonth, setHistoryDay} = popoutSlice.actions;
export const popoutReducer = popoutSlice.reducer;