import React from 'react';
import './App.css';
import { Fullpage } from './features/fullpage';

function App() {
  return (
    <div className="App">
      <Fullpage />
    </div>
  );
}

export default App;
