export type TypeButtonState = {
    letter: string;
    enabled: boolean;
    lastClickTime: number;
}

export function enableAvailableLetters(lettersAvail: string, btns: Array<TypeButtonState>) {
    lettersAvail = lettersAvail.toLowerCase();
    for(let i=0; i<btns.length; i++) {
        let btn = btns[i];
        btn.enabled = false;
        for(let l=0; l<lettersAvail.length; l++) {
            let letter = lettersAvail[l];
            if(letter === btn.letter.toLowerCase()) {
                btn.enabled = true;
                break;
            }
        }
    }
}

export function disableUsedLetters(lettersUsed: string, btns: Array<TypeButtonState>) {
    lettersUsed = lettersUsed.toLowerCase();
    for(let i=0; i<btns.length; i++) {
        let btn = btns[i];
        btn.enabled = true;
        for(let l=0; l<lettersUsed.length; l++) {
            let letter = lettersUsed[l];
            if(letter === btn.letter.toLowerCase()) {
                btn.enabled = false;
                break;
            }
        }
    }
}

export function enableAllLetters(btns: Array<TypeButtonState>) {
    for(let i=0; i<btns.length; i++)
        btns[i].enabled = true;
}