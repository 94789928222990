import { FullState } from "../fullpageSlice";
import { resetCells, setGridCellStates } from "../grid/gridSlice";
import { enableAllLetters } from "./typeButtons/typeButtonsSlice";

export function clearWordTypedResetGrid(state: FullState) {
    state.wordTyped = "";
    state.wordTypedLocations = [];
    state.invalidWordTypedLocations = [];
    enableAllLetters(state.typeButtons);
    resetCells(state.userDisplay);
    setGridCellStates(state.userDisplay, state.hintsGiven, state.wordTypedLocations, state.selectedWordIndex, state.selectedCell, state.invalidWordTypedLocations)
}