import { CellConditions, CellLocation } from "../../grid/cell/cellSlice";
import { GridState } from "../../grid/gridSlice"
import { SolveLevel } from "../../solution";
import { WordLocations } from "../../words";

export enum HintType {
    badLetter = 0,
    goodLetter = 1,
    goodWord = 2,
    badLocationWord = 3,
    badWord = 4,
    suggestWord = 5
}

export type HintState = {
    hintType: HintType,
    row: number,
    col: number,
    letters: string,
    horizontal: boolean | null,
    processed: boolean
}

function isWordAlreadySolved(gridState: GridState, word: WordLocations) {
    let r = word.row;
    let c= word.col;
    let deltaRow = word.horizontal ? 0 : 1;
    let deltaCol = word.horizontal ? 1 : 0;
    for(let i=0; i<word.word.length; i++) {
        if(gridState.cells[r][c].condition !== CellConditions.confirmed)
            return false;
        r += deltaRow;
        c += deltaCol;
    }
    return true;
}

function isWordAlreadyGuessed(gridState: GridState, word: WordLocations) {
    let r = word.row;
    let c= word.col;
    let deltaRow = word.horizontal ? 0 : 1;
    let deltaCol = word.horizontal ? 1 : 0;
    for(let i=0; i<word.word.length; i++) {
        switch(gridState.cells[r][c].condition) {
            case CellConditions.blank:
            case CellConditions.blocked:
            case CellConditions.lettersAvailable:
            case CellConditions.suggested:
            case CellConditions.incorrect:
            default:
                return false;
            case CellConditions.confirmed:
            case CellConditions.filled:
            case CellConditions.selected:
                break;
        }
        r += deltaRow;
        c += deltaCol;
    }
    return true;
}

function isWordInSolution(word: string, solution: GridState) {
    let w = word.toLowerCase();
    for(let i=0; i<solution.words.length; i++) {
        if(w === solution.words[i].word.toLowerCase())
            return true;
    }
    return false;
}

function isWordGood(testWord: WordLocations, solution: GridState) {
    let lc = testWord.word.toLowerCase();
    for(let i=0; i<solution.words.length; i++) {
        let correctWord = solution.words[i];
        if(correctWord.word.toLowerCase() === lc) {
            if(
                testWord.col === correctWord.col &&
                testWord.row === correctWord.row &&
                (
                    testWord.horizontal === correctWord.horizontal ||
                    testWord.word.length === 1
                )
            )
                return true

        }
    }
    return false;
}

export function generateRandomHint(gridState: GridState, solution: GridState, selectedWordIndex: number | null): HintState | null {
    let testableWords: Array<WordLocations> = []
    let testableLetters: Array<CellLocation> = []

    //all these are unknown things. If the gridState shows it as good/bad, we can ignore these things.
    let goodLetters: Array<CellLocation> = []
    let badLetters: Array<CellLocation> = []
    let unknownLetters: Array<CellLocation> = []
    let goodWords: Array<WordLocations> = [];
    let badWords: Array<WordLocations> = [];
    let unknownWords: Array<WordLocations> = [];
    let unknownLittleWords: Array<WordLocations> = []

    if (selectedWordIndex !== null && 0<=selectedWordIndex && selectedWordIndex < gridState.words.length) {
        //if they want a hint on a specific word, give it to them!
        let wd = gridState.words[selectedWordIndex];
        if(!isWordAlreadySolved(gridState, wd)) {
            if(isWordGood(wd, solution)) {
                return {
                    hintType: HintType.goodWord,
                    row: wd.row,
                    col: wd.col,
                    horizontal: wd.horizontal,
                    letters: wd.word.toLowerCase(),
                    processed: false
                }
            }
            return {
                hintType: isWordInSolution(wd.word, solution) ? HintType.badLocationWord : HintType.badWord,
                row: wd.row,
                col: wd.col,
                horizontal: wd.horizontal,
                letters: wd.word.toLowerCase(),
                processed: false
            }
        }
    }

    //then populate all the guessed word things
    for(let i=0; i<gridState.words.length; i++) {
        let wd = gridState.words[i];
        if(wd.solveLevel === SolveLevel.guessed) {
            if(!isWordAlreadySolved(gridState, wd)) {
                testableWords.push(wd);
                if(isWordGood(wd, solution))
                    for(let j=0; j<wd.word.length; j++) //weight the word based on it's length
                        goodWords.push(wd);
                else
                    for(let j=0; j<wd.word.length; j++) //weight the word based on it's length
                        badWords.push(wd);
            }
        }
    }

    if(badWords.length > 0) {
        let choice = Math.floor(badWords.length * Math.random());
        let wd = badWords[choice];
        return {
            hintType: isWordInSolution(wd.word, solution) ? HintType.badLocationWord : HintType.badWord,
            row: wd.row,
            col: wd.col,
            horizontal: wd.horizontal,
            letters: wd.word.toLowerCase(),
            processed: false
        }
    }

    //next populate all the letter things
    for(let row=0; row<gridState.numRows; row++) {
        for(let col=0; col<gridState.numCols; col++) {
            switch(gridState.cells[row][col].condition) {
                // @ts-ignore
                case CellConditions.filled: //hints about filled letters 2x more likely
                    testableLetters.push({row: row, col: col})
                    if(gridState.cells[row][col].text?.toLowerCase() === solution.cells[row][col].text?.toLowerCase())
                        goodLetters.push({row: row, col: col})
                    else
                        badLetters.push({row: row, col: col})
                    break;
                case CellConditions.blank:
                case CellConditions.lettersAvailable:
                    testableLetters.push({row: row, col: col})
                    unknownLetters.push({row: row, col: col});
                    break;
                default:
                    break;
            }
        }
    }

    if(badLetters.length > 0) {
        let hint = Math.floor(Math.random() * badLetters.length);
        let bl = badLetters[hint]
        return(
            {
                hintType: HintType.badLetter,
                row: bl.row,
                col: bl.col,
                letters: gridState.cells[bl.row][bl.col].text?.toLowerCase() || "",
                horizontal: null,
                processed: false
            }
        )
    }

    //now populate all the unknown word things
    for(let i=0; i<solution.words.length; i++) {
        let solveWord = solution.words[i];
        if(solveWord.word.length > 4)
            continue;   //no word clues for 5,6,7 & 8 letter words
        let lword = solveWord.word.toLowerCase();
        let foundMatch = false;
        for(let j=0; j<gridState.words.length; j++) {
            let userWord = gridState.words[j];
            if(userWord.word.toLowerCase() !== lword)
                continue;
            if(
                userWord.col === solveWord.col &&
                userWord.row === solveWord.row &&
                (userWord.horizontal === solveWord.horizontal || lword.length === 1)
            ) {
                switch(userWord.solveLevel) {
                    case SolveLevel.guessed:
                    case SolveLevel.confirmed:  //more just confirming all the states on what to do
                    case SolveLevel.clue:
                    case SolveLevel.guessLetter:    //just in case they clicked into a one letter word
                        foundMatch = true;
                        break;
                    case SolveLevel.hidden:
                    case SolveLevel.ignore:
                    case SolveLevel.suggested:
                    case SolveLevel.doesNotExist:
                    case SolveLevel.wrongLocation:
                    default:
                        continue;
                }
            }

        }
        if(!foundMatch) {
            if(!isWordAlreadyGuessed(gridState, solveWord)) { //maybe two parallel words had this one covered.
                if(solveWord.word.length >= 3)     //word clues for 3/4 letter words
                    unknownWords.push(solveWord);
                else
                    unknownLittleWords.push(solveWord); //1/2 letter words at the end
            }
        }
    }

    if(unknownWords.length === 0)
        unknownWords = unknownLittleWords;

    // at this point, we want to go between unknown letters and words
    let hintChoice = Math.floor(Math.random() * (unknownWords.length + unknownLetters.length));
    if(hintChoice >= unknownWords.length) {
        //we're providing a hint about a letter
        hintChoice -= unknownWords.length;
        let loc = unknownLetters[hintChoice];
        let r= loc.row;
        let c = loc.col;
        let cell = gridState.cells[r][c];
        let soln = solution.cells[r][c];
        if(cell.condition === CellConditions.filled) {
            if(cell.text?.toLowerCase() === soln.text?.toLowerCase()) {
                return {
                    hintType: HintType.goodLetter,
                    row: r,
                    col: c,
                    letters: soln.text?.toLowerCase() || '',
                    horizontal: null,
                    processed: false
                }
            }
        }
        //if it's here, it must be letters available or blank
        // we're giving them another random letter
        return {
            hintType: HintType.goodLetter,
            row: r,
            col: c,
            letters: soln.text?.toLowerCase() || '',
            horizontal: null,
            processed: false
        }
    }
    if(hintChoice < unknownWords.length) {
    // we're generating a word clue
        let testWord = unknownWords[hintChoice];
        return {
            hintType: HintType.suggestWord,
            row: testWord.row,
            col: testWord.col,
            horizontal: testWord.horizontal,
            letters: testWord.word.toLowerCase(),
            processed: true
        }
    }

    //it theoretically should never get this far
    // but now we move on to good words or letters
    hintChoice = Math.floor(Math.random() * ((Math.random()+1)*0.5) * (goodLetters.length + goodWords.length));
    if(hintChoice >= goodLetters.length) {
        hintChoice -= goodLetters.length;
        let correctWord = goodWords[hintChoice];
        return {
            hintType: HintType.goodWord,
            row: correctWord.row,
            col: correctWord.col,
            horizontal: correctWord.horizontal,
            letters: correctWord.word.toLowerCase(),
            processed: false
        }
    }
    if(hintChoice < goodLetters.length) { //in event length is 0
        let goodLetter = goodLetters[hintChoice]
        let r = goodLetter.row;
        let c = goodLetter.col;
        return {
            hintType: HintType.goodLetter,
            row: r,
            col: c,
            letters: solution.cells[r][c].text?.toLowerCase() || '',
            horizontal: null,
            processed: false
        }
    }
    //no hint
    return null;
}