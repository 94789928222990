import './popoutStyle.css'
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

export function RulesContent() {
    const menuSelection = useSelector((state: RootState) => state.popout.menuSelection);

    if (menuSelection !== "rules")
        return (<></>);

    return (
        <ul>
            <li>Unique letters in each row</li>
            <li>Unique letters in each column</li>
            <li>Grid size varies from 6x6 to 10x10, but is typically 8x8</li>
            <li>Number of letters in each puzzle varies from 6 to 10, but is typically 8</li>
            <li>Adjacent letters form words, acronyms, abbreviations, slang, proper nouns, etc.</li>
            <li>Words <i>shouldn't</i> duplicate, but no promises made.</li>
            <li>Some of the 2 & 3 letter words - <i>*grimace*</i> - They may not be that common, and can be quite the stretch. Consider <i>ade</i>, which is apparently slang for lemonade. Yeah... who knew?</li>
        </ul>
    )
}