import './popoutStyle.css'
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

export function HowToPlayContent() {
    const menuSelection = useSelector((state: RootState) => state.popout.menuSelection);

    if (menuSelection !== "howtoplay")
        return (<></>);

    return (
        <ul>
            <li>Tap the letter buttons to spell out a word</li>
            <li>Possible locations highlight on the grid</li>
            <li>To add the word, tap the highlighted grid cells</li>
            <li>If only one place is highlighted, you can add by tapping the word box</li>
            <li>Tapping the red circle button clears out the word</li>
            <li>Hints identifying bad letters or words can be removed by tapping them or submitting the next word</li>
            <li>To remove a word, tap it. If the other orientation is desired tap it again. Then tap the red circle button.</li>
        </ul>
    )
}