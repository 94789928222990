export type TimerState = {
    startTimeEpoch: number,
    resolution: number, // digits past second
}

export type TimerStates = {
    fullTimer: TimerState
    refreshSpeed: number // in milliseconds
    currentTimeEpoch: number,
    timerId: NodeJS.Timeout | null;
}

export function millisecondsToString(ms: number, resolution: number) {
    let numHours = Math.floor(ms/3600000);
    ms -= numHours * 3600000;
    let numMinutes = Math.floor(ms/60000);
    ms = ms - numMinutes * 60000;
    let seconds = ms / 1000;
    let displayString = "";

    let secondsDisplay: string = seconds.toFixed(resolution);

    //first hours portion, please no
    if(numHours > 0)
        displayString = `${numHours}:`

    //then minutes portion
    if(numMinutes >= 10)
        displayString += `${numMinutes}:`
    else if(numHours > 0)
        displayString += `0${numMinutes}:`
    else if(numMinutes > 0)
        displayString += `${numMinutes}:`

    //now seconds portion
    if(seconds >= 10)
        displayString += secondsDisplay;
    else if(displayString !== "") // under 10 seconds, and stuff beforehand
        displayString += `0${secondsDisplay}`
    else //under 10 seconds, nothing beforehand
        displayString += secondsDisplay

    return displayString;
}


export function getInitialTimerState() {    // just like this stuff being collapsable and not globally scoped
    let now = Date.now()

    const initialTimerState: TimerStates = {
        currentTimeEpoch: now,
        refreshSpeed: 100,
        fullTimer: {
            startTimeEpoch: now,
            resolution: 1,
        },
        timerId: null
    }
    return initialTimerState;
}

export function stopTimer(state: TimerStates) {
    if(state.timerId !== null) {
        clearInterval(state.timerId);
        state.timerId = null;
    }
}
