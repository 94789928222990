import './popoutStyle.css'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { getDefinition, updateDefinitionWord, updateWordTyped } from './wordDefsSlice';

export function WordDefinitionContent() {
    const menuSelection = useSelector((state: RootState) => state.popout.menuSelection);
    const wordTyped = useSelector((state: RootState) => state.wordDef.wordTyped);
    const defnLookup = useSelector((state: RootState) => state.wordDef.defnLookup);
    const matchWords = useSelector((state: RootState) => state.wordDef.matchWordType);
    const dispatch = useDispatch();

    if(menuSelection !== "defn") return (<></>);

    let options = [];
    for(let i=0; i<matchWords.length; i++) {
        options.push((
            <option
                key={i}
                value={matchWords[i]}
                onClick={(e) => {
                    dispatch(updateDefinitionWord(matchWords[i]))
                }}
            >{matchWords[i]}</option>
        ))
    }

    let defn: string = getDefinition(defnLookup, "Type a word or select from the box to get a rudimentary definition. Not a complete list of all words in the puzzles provided.");
    let dropdownBoxSize = 10; // Math.min(10,Math.max(3,matchWords.length))

    return (
        <div>
            <div className='wordLookup'>
                <h3>Word Lookup</h3>
                <div className="lhs">
                    <input type="text" className="inputBox" value={wordTyped} maxLength={10}
                        onChange={(e) => {
                            let value = e.target.value.toUpperCase();
                            dispatch(updateWordTyped(value))
                            dispatch(updateDefinitionWord(value))
                        }}
                    />
                    <select size={dropdownBoxSize} className="wordLookupBox">
                        {options}
                    </select>
                </div>
                <span className="defnBox">{defn}</span>
            </div>
            <hr />
            <p>
                Do you look at some of those "words" or acronyms and be like,
                <i>"Where in the world did that come from?"</i>
            </p><p>
                The short answer, the internet. There are lots of
                abbreviations and acronyms out there, especially when
                looking at two and three letter words. I also thought obscure
                words would be kind of cool. It's not, so there's not as many
                of those anymore (the first week of puzzles are pretty gnarly).
            </p><p>
                Making a puzzle is actually quite difficult. If you want to
                have large words next to one another, sometimes you really have
                to stretch the imagination. Puzzles that are more filled in are also
                more entertaining/intimidating to solve.
            </p>
            <p>
                While there are many acronyms, automatic shoe-ins include
                states in the USA and the periodic table of elements. While some
                older puzzles will randomly sneak in from my initial batch, I'll try
                to keep it to more common ones from this point forward, but if the
                larger words need it for the puzzle... all bets are off!
            </p>
        </div>
    )
}