import { useDispatch, useSelector } from 'react-redux'
import './popoutStyle.css'
import { RootState } from '../../app/store'
import { toggle } from './popoutSlice';
import { MenuSelect } from './menuSelect/menuSelect';
import { RulesContent } from './rules';
import { HowToPlayContent } from './howToPlay';
import { HintsContent } from './hints';
import { ScoringContent } from './scoring';
import { DevelopmentContent } from './development';
import { SummaryContent } from './summary';
import { HistoryContent } from './history/history';
import { WordDefinitionContent } from './wordDefs';
import { isStateAllowNextTutorialStep } from '../tutorial/tutorialSlice';
import { nextTutorialState } from '../fullpageSlice';

export function Popout() {

    const hidden = useSelector((state: RootState) => state.popout.hidden);
    const tutorialState = useSelector((state: RootState) => state.fullpage.tutorial.currentStep)
    const dispatch = useDispatch();

    let classNames = hidden ? "popout hide" : "popout"

    return (
        <>
        <div className="toggle" onClick={(e) => {

            dispatch(toggle(tutorialState))
            let disp = hidden ? "show" : "hide"
            if(isStateAllowNextTutorialStep(tutorialState, disp)) {
                dispatch(nextTutorialState());
            }
        }
        }>Menu</div>
        <div className={classNames}>
            <MenuSelect />
            <SummaryContent />
            <RulesContent />
            <HowToPlayContent />
            <HintsContent />
            <ScoringContent />
            <DevelopmentContent />
            <HistoryContent />
            <WordDefinitionContent />
        </div>
        </>
    )
}