export enum TutorialStep {
    INIT=1, //note that actions will be restricted for people
    INIT2, //note that actions will be restricted for people
    INIT3, //note that actions will be restricted for people
    UNIQUE_LETTERS,
    SCORING,
    SCORING2,
    SCORING3,
    SCORING4,
    WORD_DEFN,
    WORD_DEFN2,
    WORD_DEFN3,
    WORD_COLOR,
    TYPE_LEAP,
    CLEAR_LEAP,
    TYPE_HIP,
    SELECT_ALP_PRE1,
    SELECT_ALP_PRE2,
    SELECT_ALP,
    HINT_HIP_BAD1,
    HINT_HIP_BAD2,
    HINT_HIP_BAD3,
    HINT_HIP_BAD,
    CLEAR_BAD_HIP_HINT,
    TYPE_HIP_AGAIN,
    PUT_IN_LAP_LOC_PRE1,
    PUT_IN_LAP_LOC_PRE2,
    PUT_IN_LAP_LOC,
    SELECT_BAD_HIP_PRE1,
    SELECT_BAD_HIP_PRE2,
    SELECT_BAD_HIP,
    SELECT_BAD_HIP_POST1,
    REMOVE_BAD_HIP,
    TYPE_ALPHIQUE,
    SELECT_ALPHIQUE_VIA_WORD_BOX_PRE1,
    SELECT_ALPHIQUE_VIA_WORD_BOX_PRE2,
    SELECT_ALPHIQUE_VIA_WORD_BOX_PRE3,
    SELECT_ALPHIQUE_VIA_WORD_BOX,
    INDIV_LETTER1,
    INDIV_LETTER2,
    TAP_R5_C3_QUAIL_U,
    SEE_SELECTED_CELL,
    BLOCKED_INPUTS,
    TAP_NOT_U_LETTER,
    TAP_R5_C3_NOT_U,
    TAP_CLEAR_BUTTON,
    TAP_QUAIL_U_AGAIN,
    TAP_NOT_U_AGAIN,
    TAP_HINT_BAD_LETTER,
    CLEAR_BAD_LETTER,
    OPEN_MENU,
    VIEW_MENU1,
    VIEW_MENU2,
    VIEW_MENU3,
    CLOSE_MENU,
    HINT_BOARD_GOOD_PRE1,
    HINT_BOARD_GOOD, //random letter/word suggestion appears
    FINISH_PUZZLE, // no restrictions
    OPEN_MENU_END,
    TAB_TO_HISTORY,
    DONE_MSG,
    DONE_MSG2,
//    SHOW_ADS,
//    SHOW_ADS2,
//    SHOW_ADS3,
    LOAD_TODAY,
    DONE
}

export type TutorialState = {
    currentStep: TutorialStep,
    uniqueLetters: boolean,
    scoring: boolean,
    wordDefns: boolean,
    typeWordNotFitAnywhere: boolean, //leap
    typeFullWordsMultiple: boolean, // hip - select ALP location
    hintBadWord: boolean,
    removeBadWord: boolean,
    typeFullWordsSingle: boolean, //alphique
    hintGood: boolean,
    hintLetter: boolean,
    hintBadLetter: boolean,
}

export function getInitTutorialState(alreadyDone: boolean): TutorialState {
    let step = TutorialStep.INIT;
    if(alreadyDone)
        step = TutorialStep.DONE;
    return {
        currentStep: step,
        uniqueLetters: alreadyDone,
        scoring: alreadyDone,
        wordDefns: alreadyDone,
        typeWordNotFitAnywhere: alreadyDone,
        typeFullWordsMultiple: alreadyDone,
        hintBadWord: alreadyDone,
        removeBadWord: alreadyDone,
        typeFullWordsSingle: alreadyDone,
        hintGood: alreadyDone,
        hintLetter: alreadyDone,
        hintBadLetter: alreadyDone,
    }
}

export function isTutorialActionAllowed(currentState: TutorialStep, action: string, data: string): boolean {
    switch(currentState) {
        case TutorialStep.DONE:
            return true
        case TutorialStep.TYPE_LEAP:
            if(action !== "addLetterWordType")
                return false;
            if("leap".startsWith(data) && data.length <= 4)
                return true;
            return false
        case TutorialStep.CLEAR_LEAP:
            if(action === "clearWordTyped") {
                if(data === "leap")
                    return true;
            }
            if(action === "attemptAddWord") {
                if(data === "leap")
                    return true;
            }
            return false;
        case TutorialStep.TYPE_HIP:
        case TutorialStep.TYPE_HIP_AGAIN:
            if(action !== "addLetterWordType")
                return false;
            if("hip".startsWith(data) && data.length <= 3)
                return true;
            return false;
        case TutorialStep.SELECT_ALP:
            if(action === "gridCellClick") {
                switch (data) {
                    case "3_1":
                    case "3_2":
                    case "3_3":
                        return true;
                    default:
                        break;
                }
            }
            return false;
        case TutorialStep.HINT_HIP_BAD:
        case TutorialStep.TAP_HINT_BAD_LETTER:
        case TutorialStep.HINT_BOARD_GOOD:
            if(action !== "generateHint")
                return false;
            return true;
        case TutorialStep.CLEAR_BAD_HIP_HINT:
            if(action === "gridCellClick") {
                switch (data) {
                    case "3_1":
                    case "3_2":
                    case "3_3":
                        return true;
                    default:
                        break;
                }
            }
            return false;
        case TutorialStep.PUT_IN_LAP_LOC:
        case TutorialStep.SELECT_BAD_HIP:
            if(action === "gridCellClick") {
                switch (data) {
                    case "4_4":
                    case "5_4":
                    case "6_4":
                        return true;
                    default:
                        break;
                }
            }
            return false;
        case TutorialStep.REMOVE_BAD_HIP:
            if(action === "clearWordTyped") {
                if(data === "hip")
                    return true;
            }
            return false;
        case TutorialStep.TYPE_ALPHIQUE:
            if(action !== "addLetterWordType")
                return false;
            if("alphique".startsWith(data) && data.length <= 8)
                return true;
            return false;
        case TutorialStep.SELECT_ALPHIQUE_VIA_WORD_BOX:
            if(action !== "attemptAddWord")
                return false;
            if(data === "alphique")
                return true;
            return false;
        case TutorialStep.TAP_R5_C3_QUAIL_U:
        case TutorialStep.TAP_R5_C3_NOT_U:
        case TutorialStep.TAP_QUAIL_U_AGAIN:
        case TutorialStep.CLEAR_BAD_LETTER:
            if(action === "gridCellClick" && data === "5_3")
                return true;
            return false;
        case TutorialStep.TAP_NOT_U_LETTER:
        case TutorialStep.TAP_NOT_U_AGAIN:
            if(action === "addLetterWordType" && data !== "u")
                return true;
            return false;
        case TutorialStep.TAP_CLEAR_BUTTON:
            if(action === "clearWordTyped") {
                if(data !== "u")
                    return true;
            }
            return false;
        case TutorialStep.OPEN_MENU:
        case TutorialStep.OPEN_MENU_END:
            if(action === "toggleMenu") {
                if(data === "show")
                    return true;
            }
            return false;
        case TutorialStep.CLOSE_MENU:
            if(action === "toggleMenu") {
                if(data === "hide")
                    return true;
            }
            return false;
        case TutorialStep.FINISH_PUZZLE:
            if(action !== "toggleMenu")
                return true;
            return false;
        case TutorialStep.TAB_TO_HISTORY:
            if(action === "switchTab" && data === "history")
                return true;
            return false;
        case TutorialStep.DONE_MSG:
        //case TutorialStep.SHOW_ADS:
            return false;
        case TutorialStep.LOAD_TODAY:
            if(action === "toggleMenu" && data === "hide")
                return true;
            if(action === "loadBoard" && data === "today")
                return true;
            return false;
        default:
    }
    return false;
}

export function getNextStep(currentState: TutorialStep): TutorialStep {
    if(currentState === TutorialStep.DONE)
        return currentState
    currentState += 1;
    return currentState;
}

export function isStateAllowNextTutorialStep(currentState: TutorialStep, val: string) {
    switch (currentState) {
        case TutorialStep.INIT:
        case TutorialStep.INIT2:
        case TutorialStep.INIT3:
        case TutorialStep.UNIQUE_LETTERS:
        case TutorialStep.SCORING:
        case TutorialStep.WORD_DEFN:
            return false;
        case TutorialStep.TYPE_LEAP:
            return (val === "leap");
        case TutorialStep.CLEAR_LEAP:
            return (val === "leap" || val === "add_leap")
        case TutorialStep.TYPE_HIP:
        case TutorialStep.TYPE_HIP_AGAIN:
            return (val === "hip");
        case TutorialStep.SELECT_ALP:
            switch (val) {
                case "3_1":
                case "3_2":
                case "3_3":
                    return true;
                default:
                    return false;
            }
        case TutorialStep.HINT_HIP_BAD:
            return (val === "generateHint");
        case TutorialStep.CLEAR_BAD_HIP_HINT:
            switch (val) {
                case "3_1":
                case "3_2":
                case "3_3":
                    return true;
                default:
                    return false;
            }
        case TutorialStep.PUT_IN_LAP_LOC:
        case TutorialStep.SELECT_BAD_HIP:
            switch (val) {
                case "4_4":
                case "5_4":
                case "6_4":
                    return true;
                default:
                    return false;
            }
        case TutorialStep.REMOVE_BAD_HIP:
            return (val === "hip");
        case TutorialStep.TYPE_ALPHIQUE:
            return (val === "alphique");
        case TutorialStep.SELECT_ALPHIQUE_VIA_WORD_BOX:
            return (val === "add_alphique")
        case TutorialStep.TAP_R5_C3_QUAIL_U:
        case TutorialStep.TAP_R5_C3_NOT_U:
        case TutorialStep.TAP_QUAIL_U_AGAIN:
            case TutorialStep.CLEAR_BAD_LETTER:
            return (val === "5_3")
        case TutorialStep.TAP_NOT_U_LETTER:
        case TutorialStep.TAP_CLEAR_BUTTON:
        case TutorialStep.TAP_NOT_U_AGAIN:
            return (val.length === 1 && val !== "u")
        case TutorialStep.TAP_HINT_BAD_LETTER:
        case TutorialStep.HINT_BOARD_GOOD:
            return (val === "generateHint");
        case TutorialStep.OPEN_MENU:
        case TutorialStep.OPEN_MENU_END:
            return (val === "show");
        case TutorialStep.CLOSE_MENU:
            return (val === "hide");
        case TutorialStep.TAB_TO_HISTORY:
            return (val === "history");
        case TutorialStep.DONE_MSG:
        //case TutorialStep.SHOW_ADS:
            return false;
        case TutorialStep.LOAD_TODAY:
            return (val === "current");

    }
    return false;
}