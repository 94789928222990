import { SolveLevel } from "./solution";

export type WordLocations = {
    row: number,
    col: number,
    word: string,
    horizontal: boolean,
    solveLevel: SolveLevel
}

export function wasWordLocationClicked(wl: WordLocations, row: number, col: number): boolean {
    let deltaRow = wl.horizontal ? 0 : 1;
    let deltaCol = wl.horizontal ? 1 : 0;
    let r = wl.row;
    let c = wl.col;
    for(let i=0; i<wl.word.length; i++) {
        if(r === row && c === col)
            return true;
        r += deltaRow;
        c += deltaCol;
    }
    return false;
}