import './popoutStyle.css'
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

export function ScoringContent() {
    const menuSelection = useSelector((state: RootState) => state.popout.menuSelection);

    if (menuSelection !== "scoring")
        return (<></>);

    return (
        <>
        <span><b>Score low</b></span>
        <hr />
            <ul>
                <li>30 seconds = 1 point</li>
                <li>Words Added = 1 point</li>
                <li>Hint Used = 1 point</li>
            </ul>
            <hr />
        <p>You start each puzzle with 8 points.</p>
        <p>Shave points by completing words where every letter overlaps another word.</p>
        <p>Points for seconds round down.</p>
        <hr />
        </>
    )
}