import { WordLocations } from './words'

export enum SolveLevel {
    clue = 0,       // let them know it exists, but don't show on the board
    hidden = 1,     //they know nothing
    wrongLocation = 2,  // right word, wrong spot
    doesNotExist = 3,   // this word is just bad
    ignore = 4,
    suggested = 5,  //they typed a word, show it on the grid
    guessed = 6,    //they guessed this word in a particular location
    guessLetter = 7,
    confirmed = 8   //they have this word correct
}

export type FinalSolution = {
    numRows: number
    numCols: number
    words: WordLocations
}