import './popoutStyle.css'
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

export function DevelopmentContent() {
    const menuSelection = useSelector((state: RootState) => state.popout.menuSelection);

    if (menuSelection !== "development")
        return (<></>);

    return (
        <>
        <b>TODO</b><br />(* = done)
            <ul>
                <li>Go ILLINI! Oh, orange and blue is not popular. Use Green & Red. We'll see if other people actually care.</li>
                <li>Figure out how to autogenerate the puzzles.</li>
                <li>Get ads working. Still waiting on approval from Google adSense.</li>
                <li>Build out Android App, and replace adSense with adMob</li>
                <li>Build out iOS App, and replace adSense with adMob</li>
                <li>* (for new puzzles)No crazy words/acronyms. Too Hard.</li>
                <li>* Multiple solutions to a puzzle - all should be allowed. Tough one.</li>
                <li>* If you type a word that fits in a location but fails due to letter restrictions, display it on the board in red as unselectable instead of nothing, which feels like a bug.</li>
                <li>* Optimized towards portrait cellphone. Customize based on screen dimensions.</li>
                <li>* Allow tapping individual cells to enter individual letters. Disable disallowed letters.</li>
                <li>* Let users select a word, and then tap hint to verify if correct</li>
                <li>* The hint timer penalty is too aggressive</li>
                <li>* Redirect URL/play to URL/play/index.html (XML Error)</li>
                <li>* Remove Manifests. That apparently tried to make it act like an app.</li>
                <li>* The hints suck. Prioritize incorrect words, and then a mix of unknown letters/words.</li>
                <li>* New letters more helpful than confirming letters.</li>
                <li>* Word suggestion auto fill in the type box for hints. Help with them crazy ones.</li>
                <li>* Actually display a score/popup on winning.</li>
                <li>* Figure out how to not lose state on refresh (cookies?)</li>
                <li>* Button sensitivity not very good. Event handlers now onTouchEnd, onClick, onDoubleClick used and ignores invalid entries</li>
                <li>* Update the board to load by date from history so it switches at midnight local time, and plan 24 hours in advance.</li>
            </ul>
            <hr />
            <b>Notes from the developer:</b>
            <ul>
                <li>I intend this to eventually be available on iOS and Android. I've never developed for iOS before.</li>
                <li>Puzzles may repeat. A script chooses randomly among the least deployed puzzles. I typically spend a couple hours building a fresh set of 7 weekly.</li>
                <li>I'm just one person. I'm fully employed. This is a side-project. Please be patient.</li>
                <li>There are no servers or databases supporting this game - just a file server (AWS S3) and cookies.</li>
                <li>The only proof of completing a puzzle is taking a screenshot.</li>
                <li>I intend to offer a free version with ads, and a paid version without ads.</li>
            </ul>
        </>
    )
}