import './winScreenStyle.css'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import { millisecondsToString } from '../timer/timerSlice';
import { HintType } from '../userInput/hints/hintSlice';
import { getHintScore, getTimerScore, getWordScore } from './winScrenSlice';
import { useState } from 'react';
import { getDefinition } from '../popout/wordDefsSlice';

export function WinScreen() {
    const winState = useSelector((state: RootState) => state.fullpage.win);
    const timerStart = useSelector((state: RootState) => state.fullpage.puzzleStartTime);
    const timerEnd = useSelector((state: RootState) => state.fullpage.puzzleEndTime);

    const hints = useSelector((state: RootState) => state.fullpage.hintsGiven);
    const words = useSelector((state: RootState) => state.fullpage.userDisplay.words);
    const wordSolution = useSelector((state: RootState) => state.fullpage.finalSolution.words);

    const [wordSelected, setWordSelected] = useState(-1)

    if(!winState)
        return (<></>); // don't show anything if you didn't win yet

    let timeSpent = (timerEnd - timerStart);
    let timeDisplay = millisecondsToString(timeSpent, 2)
    let timerScore = getTimerScore(timeSpent);
    let hintScore: number = getHintScore(hints);
    let wordScore = getWordScore(words);
    let totScore = timerScore + hintScore + wordScore;

    let wordDisplay = []
    let sortedWords=[]
    for(let i=0; i<wordSolution.length; i++)
        sortedWords.push(wordSolution[i].word.toUpperCase())

    sortedWords = sortedWords.sort((a: string, b: string) => {
        let x = b.length - a.length;
        if(x===0){
            for(let i=0;i<a.length; i++) {
                x = a.charCodeAt(i) - b.charCodeAt(i)
                if(x!==0)
                    return x;
            }
        }
        return x
    })

    for(let i=0; i<sortedWords.length; i++)
        wordDisplay.push((
            <option
                key={`words_${i}`}
                className="myOption"
                value={i}
            >{sortedWords[i]}</option>
        ))

    let hintsGiven = []
    for(let i=0; i<hints.length; i++) {
        let h = hints[i];
        let dispText: string = "";
        let horizontal = h.horizontal ? ">" : "v"
        switch(h.hintType) {
            case HintType.badLetter:
                dispText=`Bad Letter: (${h.row+1},${h.col+1}) ${h.letters.toUpperCase()}`;
                break;
            case HintType.badLocationWord:
                dispText=`Bad Location: (${h.row+1},${h.col+1}, ${horizontal}) ${h.letters.toUpperCase()}`
                break;
            case HintType.badWord:
                dispText=`Bad Word: (${h.row+1},${h.col+1}, ${horizontal}) ${h.letters.toUpperCase()}`
                break;
            case HintType.goodLetter:
                dispText=`Good Letter: (${h.row+1},${h.col+1}) ${h.letters.toUpperCase()}`
                break;
            case HintType.goodWord:
                dispText=`Good Word: (${h.row+1},${h.col+1}, ${horizontal}) ${h.letters.toUpperCase()}`
                break;
            case HintType.suggestWord:
                dispText=`Suggestion: ${h.letters.toUpperCase()}`
                break;
        }
        hintsGiven.push((
            <option key={`hints_${i}`} className='myOption'>{dispText}</option>
        ))
    }

    let defn: string;
    if(wordSelected < 0 || wordSelected >= sortedWords.length) //bug if prior win had lots of words and is out of bounds
        defn = "Some word/acronym/abbreviation definitions available";
    else {
        let word = sortedWords[wordSelected];
        defn = getDefinition(word, "Definition not available")
    }

    return (
        <div className='youWin'>
            <table className='scoreTable animateTable'><tbody>
                <tr>
                    <td colSpan={2}><span className='winStyle'>YOU WON!</span></td>
                </tr>
                <tr className='timerScore'>
                    <td>Time ({timeDisplay})</td>
                    <td>{timerScore}</td>
                </tr>
                <tr className='hintScore'>
                    <td>Hints</td>
                    <td>{hintScore}</td>
                </tr>
                <tr className='wordScore'>
                    <td>Words</td>
                    <td>{wordScore}</td>
                </tr>
                <tr className='totalScore'>
                    <td><b>Total</b></td>
                    <td><b>{totScore}</b></td>
                </tr>
            </tbody></table>
            <table className='scoreTable'><tbody>
            <tr>
                    <td>
                        <select onChange={(e) => {
                            let val = Number(e.target.value)
                            setWordSelected(val)}
                        }>
                            <option value={-1}>Words / Abbr. / Accr.</option>
                            {wordDisplay}
                        </select>
                    </td>
                    <td><select>
                            <option>Hints</option>
                            {hintsGiven}
                        </select></td>
                </tr>
            </tbody></table>
            <span className='nextPuzzle'>New puzzle available at midnight</span>
            <span className='nextPuzzle'>Try past puzzles under Menu -&gt; History</span>
            <span className='nextPuzzle'>{defn}</span>
        </div>
    )

}