import { HintState, HintType } from "../userInput/hints/hintSlice"
import { SolveLevel } from "../solution"
import { WordLocations } from "../words"
import { CellConditions, CellLocation, CellState } from "./cell/cellSlice"
import { FullState } from "../fullpageSlice"

export type GridState = {
    cells: Array<Array<CellState>>,
    numRows: number,
    numCols: number,
    words: Array<WordLocations>,
}

export const emptyGrid: GridState = {
    cells: [],
    numRows: 0,
    numCols: 0,
    words: []
}

export function cloneGrid(gridState: GridState) {
    let ret: GridState = {
        cells: [],
        words: [],
        numRows: gridState.numRows,
        numCols: gridState.numCols
    }
    for (let row=0; row<gridState.cells.length; row++) {
        let r: Array<CellState> = []
        for(let col=0; col<gridState.cells[row].length; col++) {
            let cell = gridState.cells[row][col];
            r.push({
                row: cell.row,
                col: cell.col,
                text: cell.text,
                condition: cell.condition
            })
        }
        ret.cells.push(r);
    }
    for (let i=0; i<gridState.words.length; i++) {
        let word: WordLocations = gridState.words[i];
        ret.words.push({
            word: word.word,
            row: word.row,
            col: word.col,
            horizontal: word.horizontal,
            solveLevel: word.solveLevel
        })
    }
    return ret;
}


export function setGridCellStates(
    gridState: GridState,
    hints: Array<HintState>,
    suggestLocations: Array<WordLocations>,
    selectedWordIndex: number,
    selectedCell: CellLocation | null,
    invalidSuggestLocations: Array<WordLocations>,
) {
    for(let i=0; i<hints.length; i++) {
        let hint = hints[i];
        if (hint.processed)
            continue;
        let hWord = hint.letters.toLowerCase()
        if (hint.hintType === HintType.goodWord) {
            for(let j=0; j<gridState.words.length; j++) {
                let w = gridState.words[j];
                if(w.solveLevel === SolveLevel.hidden || w.solveLevel === SolveLevel.ignore)
                    continue;
                if(
                    w.col === hint.col &&
                    w.row === hint.row &&
                    w.word.toLowerCase() === hWord &&
                    (
                        w.horizontal === hint.horizontal ||
                        hWord.length === 1
                    )
                ) {
                    w.solveLevel = SolveLevel.confirmed;
                    hint.processed = true;
                }
            }
        }
        else if (hint.hintType === HintType.badLocationWord) {
            for(let j=0; j<gridState.words.length; j++) {
                let w = gridState.words[j];
                if(w.solveLevel === SolveLevel.hidden || w.solveLevel === SolveLevel.ignore)
                    continue;
                if(
                    w.word.toLowerCase() === hWord &&
                    w.col === hint.col &&
                    w.row === hint.row && (
                        w.horizontal === hint.horizontal ||
                        w.word.length === 1
                    )
                ) {
                    w.solveLevel = SolveLevel.wrongLocation;
                    hint.processed = true;
                }
            }
        }
        else if (hint.hintType === HintType.badWord) {
            for(let j=0; j<gridState.words.length; j++) {
                let w = gridState.words[j];
                if(w.solveLevel === SolveLevel.hidden || w.solveLevel === SolveLevel.ignore)
                    continue;
                if(w.word.toLowerCase() === hWord) {
                    w.solveLevel = SolveLevel.doesNotExist;
                    hint.processed = true;
                }
            }
        }
    }
    assignLetters(gridState);
    for(let i=0; i<hints.length; i++) {
        let hint = hints[i];
        if (hint.processed)
            continue;
        let cell = gridState.cells[hint.row][hint.col]
        if(hint.hintType === HintType.goodLetter) {
            cell.condition = CellConditions.confirmed;
            cell.text = hints[i].letters;
        }
        else if(hint.hintType === HintType.badLetter) {
            if(cell.text?.toLowerCase() === hint.letters.toLowerCase()) {
                cell.condition = CellConditions.incorrect;
            }
        }
    }
    for (let i=0; i<suggestLocations.length; i++) {
        let suggestWord = suggestLocations[i];
        let deltaRow = suggestWord.horizontal ? 0 : 1;
        let deltaCol = suggestWord.horizontal ? 1 : 0;
        let r = suggestWord.row;
        let c = suggestWord.col;
        let word = suggestWord.word;
        for(let l=0; l<word.length; l++) {
            let cell = gridState.cells[r][c];
            switch(cell.condition) {
                case CellConditions.blank:
                case CellConditions.incorrect:
                case CellConditions.lettersAvailable:
                case CellConditions.suggested:
                    cell.text = word[l];
                    cell.condition = CellConditions.suggested;
                    break;
                case CellConditions.blocked: // should never happen
                case CellConditions.confirmed: // don't overwrite state
                case CellConditions.filled:
                    break;
            }

            r += deltaRow;
            c += deltaCol;

        }
    }
    if(suggestLocations.length === 0) {
        for(let i=0; i<invalidSuggestLocations.length; i++) {
            let suggestWord = invalidSuggestLocations[i];
            let deltaRow = suggestWord.horizontal ? 0 : 1;
            let deltaCol = suggestWord.horizontal ? 1 : 0;
            let r = suggestWord.row;
            let c = suggestWord.col;
            let word = suggestWord.word;
            for(let l=0; l<word.length; l++) {
                let cell = gridState.cells[r][c];
                switch(cell.condition) {
                    case CellConditions.blank:
                    case CellConditions.incorrect:
                    case CellConditions.lettersAvailable:
                    case CellConditions.suggested:
                    case CellConditions.suggestBad:
                        cell.text = word[l];
                        cell.condition = CellConditions.suggestBad;
                        break;
                    case CellConditions.blocked: // should never happen
                    case CellConditions.confirmed: // don't overwrite state
                    case CellConditions.filled:
                        break;
                }

                r += deltaRow;
                c += deltaCol;

            }

        }
    }
    if(0<=selectedWordIndex && selectedWordIndex<gridState.words.length) {
        let selectedWord = gridState.words[selectedWordIndex];
        let deltaRow = selectedWord.horizontal ? 0 : 1;
        let deltaCol = selectedWord.horizontal ? 1 : 0;
        let r = selectedWord.row;
        let c = selectedWord.col;
        let word = selectedWord.word;
        for(let l=0; l<word.length; l++) {
            let cell = gridState.cells[r][c];
            switch(cell.condition) {
                case CellConditions.blank:
                case CellConditions.incorrect:
                case CellConditions.lettersAvailable:
                case CellConditions.suggested:
                case CellConditions.suggestBad:
                case CellConditions.selected:
                case CellConditions.filled:
                case CellConditions.confirmed: // don't overwrite state
                    cell.condition = CellConditions.selected;
                    break;
                case CellConditions.blocked: // should never happen
                    break;
            }
            r += deltaRow;
            c += deltaCol;
        }
    }
    if(selectedCell !== null) {
        let cell = gridState.cells[selectedCell.row][selectedCell.col];
        switch(cell.condition) {
            case CellConditions.blank:
            case CellConditions.incorrect:
            case CellConditions.lettersAvailable:
            case CellConditions.hideSelected:
                cell.condition = CellConditions.hideSelected;
                break;
            case CellConditions.suggested:
            case CellConditions.suggestBad:
            case CellConditions.selected:
            case CellConditions.filled:
            case CellConditions.confirmed: // don't overwrite state
                cell.condition = CellConditions.selected;
                break;
            case CellConditions.blocked: // should never happen
                break;
        }
    }
}

export function assignLetters(gridState: GridState) {
    for(let i=0; i<gridState.words.length; i++) {
        let wrd = gridState.words[i];
        if(wrd.solveLevel === SolveLevel.ignore) // don't want to set the letter
            continue;
        let deltaRow = wrd.horizontal ? 0 : 1;
        let deltaCol = wrd.horizontal ? 1 : 0;
        let row = wrd.row;
        let col = wrd.col;
        for(let j=0; j<wrd.word.length; j++) {
            if(row < gridState.numRows && col < gridState.numCols) {
                gridState.cells[row][col].text = wrd.word[j].toUpperCase()
                switch(wrd.solveLevel) {
                    default:
                    case SolveLevel.clue:
                    case SolveLevel.hidden: //basically, default unknown
                    case SolveLevel.suggested: // really shouldn't happen here
                        gridState.cells[row][col].condition = Math.max(CellConditions.blank, gridState.cells[row][col].condition)
                        break;
                    case SolveLevel.doesNotExist:
                    case SolveLevel.wrongLocation:  // transitions to ignore after X amount of time
                        gridState.cells[row][col].condition = Math.max(CellConditions.incorrect, gridState.cells[row][col].condition)
                        break;
                    case SolveLevel.guessed:
                    case SolveLevel.guessLetter:
                        gridState.cells[row][col].condition = Math.max(CellConditions.filled, gridState.cells[row][col].condition)
                        break;
                    case SolveLevel.confirmed:
                        gridState.cells[row][col].condition = CellConditions.confirmed
                        break;
                }
            }
            row += deltaRow;
            col += deltaCol;
        }
    }
}

export function resetCells(gridState: GridState) {
    gridState.cells = []
    for(let row=0; row<gridState.numRows; row++) {
        let r: Array<CellState> = []
        for(let col=0; col<gridState.numCols; col++) {
            r.push({
                row: row,
                col: col,
                text: null,
                condition: CellConditions.blocked
            })
        }
        gridState.cells.push(r);
    }
}

function testWordMatchesHints(word: string, row: number, col: number, horizontal: boolean, hints: Array<HintState>) {
    let deltaRow = horizontal ? 0 : 1;
    let deltaCol = horizontal ? 1 : 0;
    let letterRC: Array<CellState> = []
    let r = row;
    let c = col;
    for(let i=0; i<word.length; i++) {
        let letter = word[i].toLowerCase();
        letterRC.push({row: r, col: c, text: letter, condition: CellConditions.suggested}) //throw away condition
        r += deltaRow;
        c += deltaCol;
    }

    // the good things are already on the board and filtered,
    // so we only need to test the bad things
    for(let h=0; h<hints.length; h++) {
        let hint = hints[h];
        if(hint.hintType === HintType.badWord || hint.hintType === HintType.badLocationWord) {
            if( row === hint.row &&
                col === hint.col &&
                horizontal === hint.horizontal &&
                word.toLowerCase() === hint.letters.toLowerCase()
            )
                return false;
        }
        if(hint.hintType === HintType.badLetter) {
            for(let i=0; i<letterRC.length; i++) {
                if( hint.row === letterRC[i].row &&
                    hint.col === letterRC[i].col &&
                    hint.letters.toLowerCase() === letterRC[i].text
                )
                    return false;
            }
        }
    }

    return true;
}

function testWordFits(
    gridState: GridState,
    word: string,
    row: number,
    col: number,
    horizontal: boolean,
    hints: Array<HintState>,
    includeAnalysis: boolean,
): boolean {
    let deltaRow = horizontal ? 0 : 1;
    let deltaCol = horizontal ? 1 : 0;

    //is the last letter in the grid?
    let r = row + (word.length-1) * deltaRow;
    let c = col + (word.length-1) * deltaCol;
    if(r >= gridState.numRows || c >= gridState.numCols)
        return false;

    //does each letter fit?
    r = row;
    c = col;
    for(let i=0; i<word.length; i++) {
        let letter = word[i].toLowerCase();

        switch(gridState.cells[r][c].condition) {
            case CellConditions.blocked:
                return false;
            case CellConditions.confirmed:
            case CellConditions.filled:
                if(letter !== gridState.cells[r][c].text?.toLowerCase())
                    return false;
                break;
            case CellConditions.blank:
            case CellConditions.incorrect:
            case CellConditions.lettersAvailable:
            case CellConditions.suggestBad:
            default:
                ;
        }
        if(includeAnalysis && !testLetterAllowedRowColumn(gridState, letter, r, c))
            return false;
        r += deltaRow;
        c += deltaCol;
    }

    //are the ends blank?
    //first letter
    r = row - deltaRow;
    c = col - deltaCol;
    if(r>=0 && c>=0 && gridState.cells[r][c].condition !== CellConditions.blocked)
        return false;

    r = row + deltaRow * word.length;
    c = col + deltaCol * word.length;
    if(r<gridState.numRows && c<gridState.numCols && gridState.cells[r][c].condition !== CellConditions.blocked)
        return false;

    if(includeAnalysis && !testWordMatchesHints(word, row, col, horizontal, hints))
        return false;

    return true;
}

function testLetterAllowedRowColumn(gridState: GridState, letter: string, row: number, col: number) {
    let lower = letter.toLowerCase();
    for(let r=0; r<gridState.numRows; r++) {
        if(r === row)
            continue;
        if(gridState.cells[r][col].text?.toLowerCase() === lower) {
            switch(gridState.cells[r][col].condition) {
                case CellConditions.confirmed:
                case CellConditions.filled:
                case CellConditions.selected:
                    return false;
                case CellConditions.blocked:
                case CellConditions.blank:
                case CellConditions.incorrect:
                case CellConditions.lettersAvailable:
                case CellConditions.hideSelected:
                default:
                    ;
            }
        }
    }

    for(let c=0; c<gridState.numCols; c++) {
        if(c === col)
            continue;
        if(gridState.cells[row][c].text?.toLowerCase() === lower) {
            switch(gridState.cells[row][c].condition) {
                case CellConditions.confirmed:
                case CellConditions.filled:
                case CellConditions.selected:
                    return false;
                case CellConditions.blocked:
                case CellConditions.blank:
                case CellConditions.incorrect:
                case CellConditions.lettersAvailable:
                default:
                    ;
            }
        }
    }

    return true;
}

export function getAvailableLetters(state: FullState, row: number, col: number): string {
    let gridState = state.userDisplay;
    if(row<0 || row>=gridState.numRows || col<0 || col>=gridState.numCols)
        return "";

    let cell = gridState.cells[row][col];
    switch(cell.condition) {
        case CellConditions.blocked:
        case CellConditions.confirmed:
        case CellConditions.filled:
            return "";
        default:
            break;
    }
    let ret = "";
    for(let i=0; i<state.lettersAvailable.length; i++) {
        let letter = state.lettersAvailable[i];
        if(testLetterAllowedRowColumn(gridState, letter, row, col))
            ret += letter;
    }

    return ret;
}

function testSingleLetterFit(gridState: GridState, letter: string, row: number, col: number, hints: Array<HintState>): boolean {
    switch(gridState.cells[row][col].condition) {
        case CellConditions.blocked:
        case CellConditions.confirmed:
        case CellConditions.filled:
            return false;
        case CellConditions.blank:
        case CellConditions.incorrect:
        case CellConditions.lettersAvailable:
        default:
            ;
    }

    let locs = [
        {row: row, col: col+1},
        {row: row, col: col-1},
        {row: row+1, col: col},
        {row: row-1, col: col},
    ]

    for(let i=0; i<locs.length; i++) {
        let loc = locs[i];
        if(loc.row < 0 || loc.row >= gridState.numRows)
            continue;
        if(loc.col < 0 || loc.col >= gridState.numCols)
            continue;
        switch(gridState.cells[loc.row][loc.col].condition) {
            case CellConditions.blank:
            case CellConditions.incorrect:
            case CellConditions.lettersAvailable:
            case CellConditions.confirmed:
            case CellConditions.filled:
            case CellConditions.suggested:
            default:
                return false;
            case CellConditions.blocked:
                ;
        }
    }

    if(!testLetterAllowedRowColumn(gridState, letter, row, col))
        return false;

    if(!testWordMatchesHints(letter, row, col, false, hints))
        return false;

    return true;
}

export type SuggestReturn = {
    good: Array<WordLocations>,
    bad: Array<WordLocations>
}

export function findSuggestedWordLocations(gridState: GridState, word: string, hints: Array<HintState>): SuggestReturn {
    let good: Array<WordLocations> = []
    let bad: Array<WordLocations> = []

    for(let row=0; row<gridState.numRows; row++) {
        for(let col=0; col<gridState.numCols; col++) {
            if(word.length === 1) {
                if (testSingleLetterFit(gridState, word, row, col, hints))
                    good.push({
                        row: row,
                        col: col,
                        word: word,
                        horizontal: true,
                        solveLevel: SolveLevel.suggested
                    })
            }
            else {
                if (testWordFits(gridState, word, row, col, true, hints, true))
                    good.push({
                        row: row,
                        col: col,
                        word: word,
                        horizontal: true,
                        solveLevel: SolveLevel.suggested
                    })
                else if (testWordFits(gridState, word, row, col, true, hints, false))
                    bad.push({
                        row: row,
                        col: col,
                        word: word,
                        horizontal: true,
                        solveLevel: SolveLevel.suggested
                    })

                if (testWordFits(gridState, word, row, col, false, hints, true))
                    good.push({
                            row: row,
                            col: col,
                            word: word,
                            horizontal: false,
                            solveLevel: SolveLevel.suggested
                        }
                    )
                else if (testWordFits(gridState, word, row, col, false, hints, false))
                    bad.push({
                            row: row,
                            col: col,
                            word: word,
                            horizontal: false,
                            solveLevel: SolveLevel.suggested
                        }
                    )
            }
        }
    }

    return {
        good: good,
        bad: bad
    }
}