import { useEffect, useState } from 'react';
import { RootState } from '../../app/store';
import './timerStyle.css'
import { useSelector } from "react-redux";
import { millisecondsToString } from './timerSlice';

export function TimePlayed() {
    const won = useSelector((state: RootState) => state.fullpage.win)
    const startTime = useSelector((state: RootState) => state.fullpage.puzzleStartTime)
    const calculateTimeSpent = () => {
        return Date.now() - startTime;
    }
    const [timeSpent, setTimeSpent] = useState(calculateTimeSpent());

    function initiateTimer(): NodeJS.Timeout  {
        let timer = setTimeout(() => {
            setTimeSpent(calculateTimeSpent())
        }, 1000)
        return timer
    }

    useEffect(() => {
        if(!won) {
            let timer = initiateTimer();
            if(timer !== null)
                return () => {clearTimeout(timer)}
        }
        return;
    })

    if(won) return (<></>)

    let displayString = millisecondsToString(timeSpent, 0);

    return (
        <div className="timePlayedLoc">
            <span className='timePlayColor'>{displayString}</span>
        </div>
    )
}
