import './hintStyle.css';
import { useDispatch, useSelector } from "react-redux"
import { generateHint } from "../../fullpageSlice"
import { AppDispatch, RootState } from "../../../app/store"
import { millisecondsToString } from "../../timer/timerSlice";
import { useEffect, useState } from 'react';
import { TutorialStep } from '../../tutorial/tutorialSlice';

export function Hints() {
    const [timeLeft, setTimeLeft] = useState(15000);
    const [targetTime, setTargetTime] = useState(Date.now()+15000);
    const [timerResolution, setTimerResolution] = useState(500);
    const nextHintSec = useSelector((state: RootState) => state.fullpage.nextHintSeconds);
    const win = useSelector((state: RootState) => state.fullpage.win)
    const tutorialState = useSelector((state: RootState) => state.fullpage.tutorial.currentStep);
    const dispatch = useDispatch<AppDispatch>();

    const calculateTimeLeft = () => {
        let now = Date.now();
        let ret = Math.max(0, targetTime - now);
        setTimerResolution(calculateTimerResolution(ret));

        return ret;
    }

    const calculateTimerResolution = (tl: number) => {
        if(tl > 4000)
            return 1000
        if(tl > 2000)
            return 100
        if(tl > 0)
            return 50
        return 0
    }

    function initiateTimer(): NodeJS.Timeout  {
        let timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft())
        }, timerResolution)
        return timer
    }

    useEffect(() => {
        if(timeLeft > 0) {
            let timer = initiateTimer();
            if(timer !== null)
                return () => {clearTimeout(timer)}
        }
        return;
    })

    if(win)
        return (<></>)

    let resolution = timeLeft > 2000 ? 0 : 1

    let displayString;
    if(timeLeft > 0)
        displayString = `Get Hint (${millisecondsToString(timeLeft, resolution)})`;
    else
        displayString = "Get Hint";

    return (
        <div className="hintButton" onClick={(e) => {
            if(timeLeft > 0)
                return; //do nothing
            dispatch(generateHint());
            if(tutorialState === TutorialStep.DONE) {
                let now = Date.now();
                setTargetTime(now + nextHintSec*1000)
                setTimeLeft(nextHintSec*1000);
                setTimerResolution(calculateTimerResolution(nextHintSec*1000))
            }
        }}>{displayString}</div>
    )
}