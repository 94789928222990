import { useDispatch, useSelector } from "react-redux";
import "./tutorialStyle.css"
import { RootState } from "../../app/store";
import { TutorialStep } from "./tutorialSlice";
import { nextTutorialState, skipTutorialState } from "../fullpageSlice";
import { useState } from "react";

export function Tutorial() {
    const tutorialState = useSelector((state: RootState) => state.fullpage.tutorial)
    const [showFullText, setFullText] = useState(true)
    const dispatch = useDispatch();

    if(tutorialState.currentStep === TutorialStep.DONE)
        return (<></>);

    function toggleFullText() {
        setFullText(!showFullText);
    }

    let tutString = "Fill me in";
    let buttonDisabled = true;
    switch(tutorialState.currentStep) {
        case TutorialStep.INIT:
            tutString = "Welcome to Alphique, a game mixing crossword puzzles and sudoku."
            buttonDisabled = false;
            break;
        case TutorialStep.INIT2:
            tutString = "During this tutorial, not everything will work as expected. You must do exactly what the tutorial tells you in order to proceed."
            buttonDisabled = false;
            break;
        case TutorialStep.INIT3:
            tutString = "In this tutorial, \"hide\" hides this text and exposes more of the game, and \"skip\" immediately exits the tutorial."
            buttonDisabled = false;
            break;
        case TutorialStep.UNIQUE_LETTERS:
            tutString = "Similar to Sudoku, each row and column contains unique letters."
            buttonDisabled = false;
            break;
        case TutorialStep.SCORING:
            tutString = "You want a low score, which is hidden until the end of the game."
            buttonDisabled = false;
            break;
        case TutorialStep.SCORING2:
            tutString = "Every 30 seconds is a point"
            buttonDisabled = false;
            break;
        case TutorialStep.SCORING3:
            tutString = "Every hint received is a point. You start the game with one hint per letter."
            buttonDisabled = false;
            break;
        case TutorialStep.SCORING4:
            tutString = "Every word or letter entered is a point, so it's better to enter full words."
            buttonDisabled = false;
            break;
        case TutorialStep.WORD_DEFN:
            tutString = "Adjacent letters will form words, abbreviations, and acronyms reading right or down."
            buttonDisabled = false;
            break;
        case TutorialStep.WORD_DEFN2:
            tutString = "Common abbreviations include US states and the periodic table of elements. Acronyms may include agencies like the FBI or CIA."
            buttonDisabled = false;
            break;
        case TutorialStep.WORD_DEFN3:
            tutString = "You may put incorrect or non-existent words on the board."
            buttonDisabled = false;
            break;
        case TutorialStep.WORD_COLOR:
            tutString = "On the board, orange backgrounds indicate correct. Red is wrong. White is unknown. Light blue is a potential location."
            buttonDisabled = false;
            break;
        case TutorialStep.TYPE_LEAP:
            tutString = "Let's get started by typing \"LEAP\" using the letter buttons."
            break;
        case TutorialStep.CLEAR_LEAP:
            tutString = "Leap doesn't fit anywhere. The 'A' is already in that row. Clear 'LEAP' by tapping the red clear button (*)."
            break;
        case TutorialStep.TYPE_HIP:
            tutString = "Let's try seeing if the word \"HIP\" fits anywhere. Type that in."
            break;
        case TutorialStep.SELECT_ALP_PRE1:
            tutString = "Woh, that fits in three different places."
            buttonDisabled = false;
            break;
        case TutorialStep.SELECT_ALP_PRE2:
            tutString = "Words typically only appear once, though some exceptions occur for two letters."
            buttonDisabled = false;
            break;
        case TutorialStep.SELECT_ALP:
            tutString = "Tap where you want it to go. You want the one with the correct 'P'."
            break;
        case TutorialStep.HINT_HIP_BAD1:
            tutString = "Let's verify that with a hint."
            buttonDisabled=false;
            break;
        case TutorialStep.HINT_HIP_BAD2:
            tutString = "Hints always push you to a particular solution, though multiple acceptable solutions may exist."
            buttonDisabled=false;
            break;
        case TutorialStep.HINT_HIP_BAD3:
            tutString = "Hints preferentially show incorrect entries."
            buttonDisabled=false;
            break;
        case TutorialStep.HINT_HIP_BAD:
            tutString = "Tap the \"Get Hint\" button."
            break;
        case TutorialStep.CLEAR_BAD_HIP_HINT:
            tutString = "The red shows this was bad. Tap the red \"hip\" to remove it."
            break;
        case TutorialStep.TYPE_HIP_AGAIN:
            tutString = "While it isn't necessarily on the board, try typing hip again."
            break;
        case TutorialStep.PUT_IN_LAP_LOC_PRE1:
            tutString = "Only two places showed up!"
            buttonDisabled = false;
            break;
        case TutorialStep.PUT_IN_LAP_LOC_PRE2:
            tutString = "The game remembers the hint that \"hip\" was bad in the top left and didn't show that."
            buttonDisabled = false;
            break;
        case TutorialStep.PUT_IN_LAP_LOC:
            tutString = "Put \"hip\" in the vertical position this time."
            break;
        case TutorialStep.SELECT_BAD_HIP_PRE1:
            tutString = "Actually, let's not. We should remove this word."
            buttonDisabled = false;
            break;
        case TutorialStep.SELECT_BAD_HIP_PRE2:
            tutString = "Tap a word to select it. Repeated taps alternate the selection between horizontal and vertical words."
            buttonDisabled = false;
            break;
        case TutorialStep.SELECT_BAD_HIP:
            tutString = "Tap \"HIP\" on the game board."
            break;
        case TutorialStep.SELECT_BAD_HIP_POST1:
            tutString = "\"HIP\" is selected, being slightly brighter with a blue tint."
            buttonDisabled = false;
            break;
        case TutorialStep.REMOVE_BAD_HIP:
            tutString = "Let's remove this by hitting the red clear button (*) while it's selected."
            break;
        case TutorialStep.TYPE_ALPHIQUE:
            tutString = "Time to \"guess\" the big one. Type \"ALPHIQUE\"."
            break;
        case TutorialStep.SELECT_ALPHIQUE_VIA_WORD_BOX_PRE1:
            tutString = "This only shows up in one place."
            buttonDisabled = false;
            break;
        case TutorialStep.SELECT_ALPHIQUE_VIA_WORD_BOX_PRE2:
            tutString = "Tapping the box where the word is typed always clears the word typed."
            buttonDisabled = false;
            break;
        case TutorialStep.SELECT_ALPHIQUE_VIA_WORD_BOX_PRE3:
            tutString = "If the word can fit in one place on the board, it also places the word."
            buttonDisabled = false;
            break;
        case TutorialStep.SELECT_ALPHIQUE_VIA_WORD_BOX:
            tutString = "Tap \"ALPHIQUE\" over the \"Get Hint\" button to add it to the board."
            break;
        case TutorialStep.INDIV_LETTER1:
            tutString = "You can also enter individual letters."
            buttonDisabled = false;
            break;
        case TutorialStep.INDIV_LETTER2:
            tutString = "This is the most intuitive way to play, but the least recommended for scoring purposes."
            buttonDisabled = false;
            break;
        case TutorialStep.TAP_R5_C3_QUAIL_U:
            tutString = "Let's select the cell to the right of the Q by tapping it."
            break;
        case TutorialStep.SEE_SELECTED_CELL:
            tutString = "We can see the cell is highlighted with a slight blue haze."
            buttonDisabled = false;
            break;
        case TutorialStep.BLOCKED_INPUTS:
            tutString = "The letters I (below), P (above), H(above), and Q (left) were disabled due to the current contents of the board."
            buttonDisabled = false;
            break;
        case TutorialStep.TAP_NOT_U_LETTER:
            tutString = "Let's go ahead and put any letter but a U here by tapping the letter buttons."
            break;
        case TutorialStep.TAP_R5_C3_NOT_U:
            tutString = "You don't actually believe that goes there, right? Tap that location on the grid to select it."
            break;
        case TutorialStep.TAP_CLEAR_BUTTON:
            tutString = "Now tap the red clear button to remove the bad letter (*)."
            break;
        case TutorialStep.TAP_QUAIL_U_AGAIN:
            tutString = "OK, let's do this again. Tap that now blank location on the grid"
            break;
        case TutorialStep.TAP_NOT_U_AGAIN:
            tutString = "Once again, tap any letter but 'U' to get a letter to appear."
            break;
        case TutorialStep.TAP_HINT_BAD_LETTER:
            tutString = "Now go ahead and tap the 'Get Hint' button. This should tell you that this individual letter is wrong!"
            break;
        case TutorialStep.CLEAR_BAD_LETTER:
            tutString = "Clear out the hint by tapping on the red letter."
            break;
        case TutorialStep.OPEN_MENU:
            tutString = "Go ahead and open the menu by tapping the menu tab in the upper left."
            break;
        case TutorialStep.VIEW_MENU1:
            tutString = "Much of the information you've seen is hiding in these tabs in some form or another."
            buttonDisabled = false;
            break;
        case TutorialStep.VIEW_MENU2:
            tutString = "We're not looking to do anything here yet. Simply highlighting it's existence."
            buttonDisabled = false;
            break;
        case TutorialStep.VIEW_MENU3:
            tutString = "There is no dedicated close button. The menu button in the upper left is a toggle."
            buttonDisabled = false;
            break;
        case TutorialStep.CLOSE_MENU:
            tutString = "Close the menu by hitting \"Menu\" again."
            break;
        case TutorialStep.HINT_BOARD_GOOD_PRE1:
            tutString = "When the board is good, hints provide a new letter or suggest a small word.";
            buttonDisabled = false;
            break;
        case TutorialStep.HINT_BOARD_GOOD:
            tutString = "Hit \"Get Hint\" again";
            break;
        case TutorialStep.FINISH_PUZZLE:
            tutString = "Alright, you have free reign to freely finish this puzzle. I'd hit the \"Hide Tutorial\" button below."
            break;
        case TutorialStep.OPEN_MENU_END:
            tutString = "Congrats, let's hop back into the menu by tapping the menu screen."
            break;
        case TutorialStep.TAB_TO_HISTORY:
            tutString = "Tab on over to history"
            break;
        case TutorialStep.DONE_MSG:
            tutString = "This tutorial is effectively finished."
            buttonDisabled = false;
            break;
        case TutorialStep.DONE_MSG2:
            tutString = "There are no servers/databases supporting this game. This tutorial will only be visible upon completion if cookies are cleared."
            buttonDisabled = false;
            break;
/*        case TutorialStep.SHOW_ADS:
            tutString = "Please turn off your ad blocker for this site."
            buttonDisabled = false;
            break;
        case TutorialStep.SHOW_ADS2:
            tutString = "There's only the one, non-intrusive banner ad, and it's the only way to support this game."
            buttonDisabled = false;
            break;
        case TutorialStep.SHOW_ADS3:
            tutString = "The area of the ad will be blocked off regardless."
            buttonDisabled = false;
            break;
*/
        case TutorialStep.LOAD_TODAY:
            tutString = "To finish this tutorial, click the \"Load Today\" button. From now on, the game will start as if you clicked this button."
            break;
        default:
            break;
    }

    let hideButtonText = showFullText ? "Hide Tutorial": "Show Tutorial"
    let buttons = [];
    if(!buttonDisabled && showFullText)
        buttons.push((
            <button
                key="next"
                disabled={buttonDisabled}
                className='NextButton'
                onClick={(e) => {
                    dispatch(nextTutorialState());
                }}
            ><b>Next</b></button>
    ))

    buttons.push((
        <button
            key="hide"
            className='NextButton'
            onClick={(e) => {
                toggleFullText();
            }}
        ><b>{hideButtonText}</b></button>
    ))

    let btns = showFullText ? (<><br />{buttons}</>) : (<>{buttons}</>)

    if(showFullText) {
        buttons.push((
            <button
                key="skip"
                className='NextButton'
                onClick={(e) => {
                    dispatch(skipTutorialState());
                }}
            ><b>Skip Tutorial</b></button>
        ))
    }
    else
        tutString = tutString.split(" ")[0] + "..."

    return (
        <div className="tutBox">
            <span>{tutString}</span>
            {btns}
        </div>
    )
}