import { createAsyncThunk } from "@reduxjs/toolkit";
import { cookies, FullState } from "../../fullpageSlice";
import { getHintScore, getTimerScore, getWordScore } from "../../winScreen/winScrenSlice";

export const loadHistoryOptions = createAsyncThunk(
    "popout/load_history_options",
    async (data, thunkAPI) => {
        const response = await fetch('./history/lookup.json')
            .then( res=>res.json())
        return response;
    }
)

export function loadPlayHistory(): Array<UserPlayState> {
    let data = cookies.get("recentScores");
    if(!data)
        return []

    let indivScores = data.split("|");
    let ret: Array<UserPlayState> = [];
    for(let i=0; i<indivScores.length; i++) {
        let indiv = indivScores[i];

        let s = indiv.split(",");
        if(s.length < 6)
            continue;
        //`${day},${play.timePlayedMs},${play.timerScore},${play.hintsGiven},${play.wordsAttempted},${play.totalScore}|`
        let val: UserPlayState = {
            day: s[0],
            timePlayedMs: Number(s[1]),
            timerScore: Number(s[2]),
            hintsGiven: Number(s[3]),
            wordsAttempted: Number(s[4]),
            totalScore: Number(s[5])
        }
        ret.push(val);
    }
    return ret;
}

export type UserPlayState = {
    day: string,    //"YYYY-MM-DD"
    timePlayedMs: number, //ms
    timerScore: number,
    hintsGiven: number, //hints.length
    wordsAttempted: number,
    totalScore: number
}

export function savePlayHistory(preExistingPlay: Array<UserPlayState>, state: FullState) {
    let day: string;
    if(state.boardLoaded === "current") {
        //can't use the start date on load
        let now = new Date();
        const offset = now.getTimezoneOffset();
        let local = new Date(state.puzzleStartTime - offset*60*1000);
        day = local.toISOString().split('T')[0];
    }
    else
        day = state.boardLoaded;

    let timePlayedMs = state.puzzleEndTime - state.puzzleStartTime;
    let timerScore = getTimerScore(timePlayedMs);
    let hintScore = getHintScore(state.hintsGiven);
    let wordScore = getWordScore(state.userDisplay.words);
    let justWonState: UserPlayState = {
        day: day,
        timePlayedMs: timePlayedMs,
        timerScore: timerScore,
        hintsGiven: hintScore,
        wordsAttempted: wordScore,
        totalScore: timerScore + hintScore + wordScore
    };

    let scores: Record<string, UserPlayState> = {}
    let keys: Array<string> = [];

    for(let i=0; i<preExistingPlay.length; i++) {
        let pep = preExistingPlay[i];
        scores[pep.day] = pep;
        keys.push(pep.day);
    }
    if(day in scores) {
        let prior = scores[day];
        if(prior.totalScore > justWonState.totalScore) {
            scores[day] = justWonState;
        }
    }
    else {
        scores[day] = justWonState;
        keys.push(day);
        preExistingPlay.push(justWonState);
    }
    keys = keys.sort().reverse();
    let cookieValue = "";
    for (let i=0; i<keys.length && i<100; i++) {
        let play = scores[keys[i]];
        //1000000 ~ 7
        // 10+7+2+2+2+3+6(dividers) = 32
        //4096 bytes total - space for 128.
        // limit to 100 most recent.
        cookieValue += `${play.day},${play.timePlayedMs},${play.timerScore},${play.hintsGiven},${play.wordsAttempted},${play.totalScore}|`
    }
    cookies.set("recentScores", cookieValue, {maxAge: 60*60*24*365});
}