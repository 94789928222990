import "./fullPageStyle.css"
import { Grid } from "./grid/grid";
import { TimePlayed } from "./timer/timer";
import { Popout } from "./popout/popout";
import { UserInput } from "./userInput/userInput";
import { WinScreen } from "./winScreen/winScreen";
import { MyAdSense } from "./ad/ad";
import { isDev } from "./fullpageSlice";
import { Tutorial } from "./tutorial/tutorial";


export function Fullpage() {
    //<div className="ad">Ad</div>
    let adClasses = isDev() ? "adContainer adContainerDisplay" : "adContainer"

    return (

        <div  className="everything">
          <div className="container">
            <Popout />
            <Tutorial />
            <div className="title">
              <span>ALPHIQUE</span>
            </div>
            <TimePlayed />
            <br />
            <Grid />
            <div className="userInputAdContainer">
              <UserInput />
              <WinScreen />

            </div>

          </div>
        </div>
      );
      /* Used to be after WinScreen
      <div className={adClasses}>
        <MyAdSense adSlot="9025677154" style={{display:"inline-block", width:"320px", height:"50px"}} />
      </div>
      */
}