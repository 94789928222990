import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const shortDefinitions: Record<string,string> = {
    "AB": "Muscle near the stomach. usually come sin the 6-pack or keg variety.",
    "AC": "Air conditioning, actinium",
    "ACL": "Anterior cruciate ligament, tissue in knee",
    "AD": "Advertisement, anno domini (in the year of the lord, like 2024 ad)",
    "ADE": "Sweet drink",
    "ADP": "Biological energy storagemolecule",
    "ADZ": "Cutting tool adze",
    "ADZE": "Cutting tool for wood",
    "AG": "Silver",
    "AI": "Artificial intelligence",
    "AK": "Alaska",
    "AL": "Aluminum, alabama",
    "ALB": "Full length vestment",
    "ALP": "Mountains in europe",
    "ALPHIQUE": "The game you are playing right now",
    "ALS": "Advanced life support, emts get als training",
    "ALT": "Alternative, it's on your keyboard!",
    "AM": "Americium, ante meridiem (before noon), amplitude modulation on radio",
    "AMOR": "Love",
    "ANG": "Abbreviation for angular",
    "ANI": "Type of bird",
    "ANSER": "Genus of birds",
    "ANU": "South american herb",
    "APR": "Annual percentage rate",
    "AR": "Arkansas, argon, augmented reality",
    "AS": "Preposition, arsenic",
    "ASL": "American sign language",
    "ASP": "Snake",
    "AT": "Preposition specifying where or when, astatine",
    "ATM": "Automated teller machine to get yo money, atmosphere",
    "ATOLE": "Corn meal",
    "ATP": "Biological molecule for energy",
    "AU": "Gold",
    "AUG": "August",
    "AUK": "Type of bird",
    "AUM": "Unit of liquid capcity for wine",
    "AV": "Audio video, those red, white, and yellow cables from yester year",
    "AVE": "Avenue",
    "AWL": "Tool for making holes",
    "AWN": "Bristles and grasses",
    "AZ": "Arizona",
    "B": "Boron",
    "BA": "Barium, noise a sheep makes",
    "BAP": "Small bun",
    "BC": "Before christ",
    "BE": "Before christian era, before the common era",
    "BEL": "Ten decibels",
    "BH": "Bohrium",
    "BI": "Bismuth",
    "BK": "Black, berkelium, burger king",
    "BLT": "Bacon lettuce tomato sandwich",
    "BMR": "Basal metabolic rate, minimum number calories to survive",
    "BOS": "Genus of cattle",
    "BPM": "Beats per minute",
    "BPS": "Bits per second",
    "BR": "Bromine",
    "BRIO": "With energy and confidence",
    "BS": "Bachelor of science, yep that is some bs",
    "BTU": "British thermal unit, see your power and gas bill",
    "C": "Carbon",
    "CA": "California, calcium",
    "CAT": "Common pet, computed axial tomorgraphy, medical imaging",
    "CBD": "Cannabidiol",
    "CD": "Compact disc music, cadmium, certificate of deposit",
    "CE": "Cerium, common era alternative to ad",
    "CEO": "Chief executive officer",
    "CF": "Californium, cubic feet",
    "CFO": "Chief financial officer",
    "CHI": "Greek letter 22",
    "CILANTRO": "A spice some think tastes like soap",
    "CL": "Chlorine, centiliter",
    "CLI": "Command line interface",
    "CM": "Centimeter, curium",
    "CMB": "Cosmic microwave background",
    "CN": "Copernicium",
    "CNS": "Central nervous system, your brain and spine",
    "CO": "Colorado, cobalt, company",
    "COIR": "Coconut husk fiber",
    "COL": "Colonel",
    "COLA": "A drink like pop",
    "COX": "Helmsman of ship",
    "CPA": "Certified public accountant",
    "CPI": "Consumer price index",
    "CPR": "Cardiopulmnonary resuscitation",
    "CPU": "Central processing unit",
    "CROTAL": "Common lichen on rocks",
    "CRT": "Cathode ray tube",
    "CS": "Cesium",
    "CST": "Central standard time",
    "CT": "Connecticut",
    "CU": "Copper",
    "CUR": "Type of dog",
    "DAG": "Hanging end opr shred",
    "DAH": "Three times longer than dot in morse code",
    "DAK": "Transport by relays of men and horses",
    "DAL": "Indian dish",
    "DB": "Decibels",
    "DC": "District of columbia",
    "DCI": "Drum corps international",
    "DE": "Delaware",
    "DEA": "Drug enforcement administration",
    "DEB": "A debutante, young woman making debut into society",
    "DEC": "December",
    "DIBS": "Making a claim, ie. i got dibs on the front seat!",
    "DKG": "Dekagram",
    "DKL": "Dekaliter",
    "DKM": "Dekameter",
    "DL": "Download, down lo",
    "DMZ": "Demilitarized zone",
    "DOJ": "Department of justice",
    "DOL": "Dollar, department of labor",
    "DR": "Drive, doctor",
    "DS": "Darmstadtium",
    "DSL": "Digital subscriber line, ie slow internet",
    "DUI": "Don't do this, driving under the influence",
    "DUN": "Brownish grey color",
    "DY": "Dysprosium",
    "EAS": "Emergency alert system",
    "EBS": "Emergency broadcast system",
    "ECG": "Electrocardiogram or ekg",
    "ED": "Education, live drivers ed",
    "EFT": "Electronic funds transfer",
    "EGIS": "A shield or protection",
    "EHF": "Extremely high frequency",
    "EKG": "Electrocardiogram",
    "EL": "An elevated subway, like in sweet home chicago",
    "ELD": "Old age",
    "EMF": "Electromotive force - voltage",
    "EN": "English",
    "EOS": "End of service",
    "EPA": "Environmental protection agency",
    "EPI": "An epi pen",
    "EPIZOAN": "External parasitic organism like fleas",
    "ER": "Emergency room, erbium",
    "ERG": "Unit of work cm g second",
    "ERN": "European eagle",
    "ES": "Einsteinium",
    "ESQ": "Esquire",
    "EST": "Eastern standard time",
    "ETA": "Estimated time of arrival",
    "ETC": "Et cetera",
    "ETD": "Estimated time of departure",
    "EU": "Europe, eropium",
    "EV": "Electron volt, electric vehicle",
    "F": "Fluorine",
    "FA": "Fourth note in scale do re mi fa so la ti do",
    "FAGIN": "Adult who teaches crime",
    "FAO": "Food and agriculture organization of united nations",
    "FAQ": "Frequetly asked questions",
    "FE": "Iron",
    "FECUND": "Fertile, can make lotsa babies",
    "FEDORA": "Type of hat",
    "FEN": "Low marshy land",
    "FET": "Field effect transistor",
    "FEZ": "Hat",
    "FL": "Flerovium, florida",
    "FM": "Fermium, frequency modulation on the radio",
    "FPS": "Frames per second",
    "FR": "Francium, crance",
    "FRI": "Friday",
    "FT": "Feet, free throw",
    "FTC": "Federal trade commission",
    "FTO": "Flexible time off",
    "FTP": "File transfer protocol",
    "FUG": "Stuffy atmosphere poorly ventilated",
    "GA": "Georgia, gallium",
    "GB": "Gigabyte, 1,073,741,824 bytes",
    "GD": "Gadolinium",
    "GDP": "Gross deomestic product",
    "GE": "Germanium",
    "GEN": "Short for generation, like gen z",
    "GENISTA": "Genus of flowering plants",
    "GHZ": "Gigahertz",
    "GIB": "Wood or metal bolt",
    "GNP": "Gross national product",
    "GNU": "Large hooved animal",
    "GOY": "Non-jew",
    "GPA": "Grade point average",
    "GPS": "Global positioning system",
    "GROATS": "Food, hulled grain",
    "GUI": "Graphical user interface",
    "GUR": "Dark brown sugar",
    "GYP": "Cheat or swindle",
    "H": "Hydrogen",
    "HA": "Lol, no definition here.",
    "HAP": "Come about by chance",
    "HE": "Dude, helium",
    "HEL": "Norse goddess of the dead",
    "HF": "Hafnium",
    "HFC": "Hydrofluorocarbons, bad stuff for the atmosphere, gas in pressurized containers",
    "HG": "Mercury",
    "HI": "Hello hawaii",
    "HIE": "Go quickly",
    "HIN": "Ancient hebrew measure of volume",
    "HM": "Hm, now sure how to define this",
    "HO": "Holmium",
    "HOD": "Tray or trough",
    "HS": "High school, hassium",
    "I": "Iodine, first person you",
    "IA": "Iowa",
    "ID": "Idaho, identification",
    "IDF": "Israel defense force",
    "IE": "That is, an example, internet explorer",
    "IG": "I guess instagram",
    "IL": "Illinois",
    "IM": "I am an instant message",
    "IMF": "International monetary fund",
    "IMO": "In my opinion",
    "IMU": "Hawaiian cooking pit",
    "IN": "Preposition, indium, indiana",
    "INC": "Incorporated",
    "INCA": "Indigenous people of peru",
    "INGESTA": "Food taken into the body",
    "IO": "Input output, moon of jupiter",
    "IP": "Internet protocol, computer address, intellectual property",
    "IPO": "Initial public offering",
    "IQ": "Intelligence quote",
    "IR": "Infrared, iridium",
    "IRA": "Individual retirement account",
    "IRL": "In real life",
    "JAK": "Large tropical asian tree",
    "JAT": "Scattered people in india, pakistan",
    "K": "Potassium",
    "KAT": "Evergreen shrub leaves",
    "KB": "Kilobyte, 1024 bytes",
    "KEA": "Parrot",
    "KEB": "Miscarry a lamb",
    "KEN": "Not area of knowledge",
    "KG": "Kilogram",
    "KHZ": "Kilohertz",
    "KM": "Kilometer",
    "KOB": "Antelope",
    "KPH": "Kilometers per hour",
    "KR": "Krypton",
    "KS": "Kansas",
    "KWA": "Group of languages around niger and congo",
    "KY": "Kentucky",
    "LA": "Lousiana, los angeles, lanthanum, 6th note in scale",
    "LAC": "Substance from scale insects for sealing wax",
    "LAM": "Hit hard",
    "LAN": "Local area network",
    "LARP": "Live action role play",
    "LAV": "Short for lavatory or bathroom",
    "LB": "Pound",
    "LCD": "Liquid crystal display",
    "LCM": "Least common multiple in math",
    "LEA": "Open grassy land",
    "LEI": "Hawaiian neckalce",
    "LEK": "Group of male animal courtship display",
    "LEO": "Fifth zodiak sign",
    "LI": "Lithium",
    "LIEN": "Financial claim on your possessions if you do not pay owed money",
    "LIN": "Linear",
    "LM": "Lumen",
    "LN": "Natural log",
    "LO": "Lo and behold",
    "LOAFER": "A lazy person",
    "LOTA": "A polished brass or copper, round, water pot, typically in india",
    "LP": "Long play record, low pressure",
    "LR": "Lawrencium",
    "LSD": "Hallucinogen",
    "LT": "Less than, lieutenant",
    "LU": "Lutetium",
    "LV": "Livermorium",
    "LYE": "Strong alkaline solution used to make soap",
    "MA": "Massachusetts",
    "MB": "Megabyte, or 1,048,576 bytes",
    "MBA": "Master of business administration",
    "MC": "Moscovium, leader of party like a dj",
    "MCG": "Microgram",
    "MCINTOSH": "Old school mac computers. apparently the a is optional.",
    "MD": "Maryland, mendelevium, doctor of medicine",
    "ME": "You, maine",
    "MEQ": "Milliequivalent",
    "MEW": "Cry like a cat",
    "MF": "Mezzo forte, medium loud in music",
    "MFA": "Multi factor authentication",
    "MG": "Milligram, magnesium",
    "MHO": "Conductivity unit",
    "MHZ": "Megahertz",
    "MI": "Michigan, mile",
    "MIC": "Microphone",
    "MJ": "Megajoule",
    "ML": "Milliliter, machine learning",
    "MN": "Minnesota, manganese",
    "MO": "Missouri, molybdenum",
    "MOA": "Minute of angle, one sixtieth of one degree",
    "MOL": "Unit for avogadros number",
    "MORAY": "Type of eel",
    "MOT": "Clever comment",
    "MP": "Military police, megapascal",
    "MPH": "Miles per hour",
    "MPS": "Meters per second",
    "MR": "Title for a dude, mister",
    "MRI": "Magnetic resonance imaging",
    "MRNA": "Messenger rna",
    "MRS": "Title for married woman",
    "MS": "Miss, mississippi, millisecond",
    "MSG": "Message, food preservative monosodium glutamate",
    "MST": "Mountain standard time",
    "MT": "Meitnerium, mountain, montana",
    "MU": "Greek letter",
    "MV": "Millivolt, megavolt",
    "MVP": "Most valuable player",
    "N": "Nitrogen",
    "NA": "Sodium",
    "NAPE": "Back of neck",
    "NB": "Niobium",
    "NC": "North carolina",
    "NCA": "National college association",
    "ND": "Neodymium, north dakota",
    "NDA": "Non disclosure agreement",
    "NE": "Nebraska, north east, neon",
    "NEAP": "Neap tide in 1st and 3rd quarter of moon",
    "NEB": "Beak of bird or tortoise",
    "NGO": "Non governmental organizations in un charter",
    "NH": "New hampshire, nihonium",
    "NI": "Nickel",
    "NIB": "Bill or beak, small pointed",
    "NICAD": "Nickel cadmium battery",
    "NIH": "National institute of health",
    "NIM": "Game with counters",
    "NJ": "New jersey",
    "NJORD": "Norse god of sea",
    "NLP": "Natural language processing, ie chat gpt",
    "NM": "New mexico",
    "NMR": "Nuclear magnetic resonance",
    "NO": "Nobelium, negative",
    "NOB": "Jack in same suit as starter in cribbage, head",
    "NOCK": "Notch in arrow",
    "NOG": "Beverage like egg nog",
    "NP": "Neptunium, no problem",
    "NPC": "Non playing character in games",
    "NRA": "National rifle association",
    "NREM": "Non rem sleep",
    "NS": "Nanosecond",
    "NSA": "National security agency",
    "NSAID": "Nonsteroidal anti inflammatory drug, like aspirin or ibuprofen",
    "NSF": "Non sufficient funds, national science foundation",
    "NT": "New testament",
    "NU": "Greek letter",
    "NV": "Nevada",
    "NW": "Northwest",
    "NY": "New york",
    "NYSE": "New york stock exchange",
    "NYX": "Greek goddess of niught",
    "O": "Oxygen",
    "OBI": "Broad sash from japaense women and children",
    "OD": "Overdose",
    "OG": "Oganesson, element 118",
    "OH": "Oh, it's ohio",
    "OK": "Okay, oklahoma",
    "OLM": "Aquatic slamander",
    "ONI": "Japanese demonic creature",
    "OP": "Original poster, over powered",
    "OPT": "To make a choice",
    "OR": "Oregon or something else",
    "ORC": "Humanoid monster like a goblin",
    "ORG": "Organization",
    "OS": "Operating system, osmium",
    "OT": "Overtime",
    "OTC": "Over the counter",
    "OW": "Ouch",
    "P": "Phosphorous",
    "PA": "Protactinium, pennsylvania",
    "PAC": "Political action committee",
    "PAS": "A step in ballet",
    "PAX": "Forced peace on an area",
    "PB": "Lead",
    "PBR": "Pabst blue ribbon",
    "PBS": "Public broadcasting service - tv",
    "PC": "Personal computer",
    "PD": "Palladium",
    "PDA": "Public display of affection",
    "PE": "Physical education",
    "PEL": "Smallest pixel on crt screen",
    "PG": "Parental guidance for movies",
    "PH": "Potential hydrogen, acidic or basic solutions",
    "PI": "3.14159",
    "PIA": "Membrane around brain, perennial herb, privacy impact assessment",
    "PIQUE": "Stimulate curiosity, feeling irritated",
    "PLOVER": "A type of bird",
    "PM": "Private message, promethium, post meridiem after noon",
    "PMS": "Premenstrual syndrome",
    "PO": "Postal office, po box, polonium",
    "POA": "Power of attorney",
    "POB": "Place of birth, post office box, dark green hot chili",
    "POI": "Point of interest",
    "POL": "Politician",
    "POX": "Disease like chicken pox or smallpox",
    "PR": "Puerto rico, pull request, praseodymium",
    "PRN": "Pro re nata, or as the thing is needed",
    "PS": "Postscript written after",
    "PSA": "Public service announcement",
    "PSF": "Pounds per square foot",
    "PSI": "Pounds per square inch",
    "PST": "Pacific standard time",
    "PT": "Platinum, point, physical therapy",
    "PTO": "Paid time off",
    "PU": "Plutonium",
    "PUD": "Pudding",
    "PVC": "Polyvinyl chloride, plumbing pipe material",
    "PX": "Post exchange, pixel",
    "PYX": "Small box, religious or testing weights",
    "QAT": "Shrub",
    "QED": "Quod erat demonstrandum, which was to be demonstrated, proven",
    "QI": "Life force",
    "RA": "Egyption sun god, radium",
    "RAD": "Pretty cool unit for radiation",
    "RALE": "Abnormal breathing sound",
    "RAM": "Goat like animal with random access memory",
    "RB": "Rubidium",
    "RBI": "Runs batted in",
    "RD": "Road",
    "RE": "Reply, rhenium, second note in scale",
    "REB": "Jewish equiv of mister",
    "REF": "Referee",
    "REM": "Rapid eye movement sleep",
    "RES": "Particular thing, especially legal",
    "RET": "To soak to loosen woody fiber",
    "RF": "Radio frequency, rutherfordium",
    "RG": "Roentgenium",
    "RH": "Rhodium",
    "RHEA": "South american ratite bird",
    "RI": "Rhode island",
    "RN": "Radon",
    "RNA": "Nucleic acid with ribose",
    "ROC": "Legendary bird in indian ocean area",
    "ROE": "Fish eggs, return on equity",
    "ROM": "Read only memory, like cartridges from games of old",
    "ROTA": "Rotation of dutes",
    "RPM": "Revolutions per minute",
    "RU": "Ruthenium",
    "RUBATO": "Music tempo manipulation to add expression",
    "RV": "Recreational vehicle, like a camper",
    "RYA": "Scandinavian handwoven rug",
    "S": "Sulfur",
    "SAC": "Hollow space or container",
    "SAX": "Saxophone",
    "SB": "Antimony",
    "SC": "Scandium, south carolina",
    "SCAD": "Large amount",
    "SCD": "Sickle cell disease",
    "SCID": "Severe combined immunodeficiency",
    "SD": "South dakota",
    "SE": "South east, selenium",
    "SEC": "Securities and exchange commission",
    "SEN": "Senator, senior",
    "SG": "Seaborgium element 106",
    "SGT": "Seargant",
    "SH": "Shipping and handling, be quiet",
    "SI": "Silicon, system internaional units",
    "SIB": "Sibling",
    "SIC": "Sic the dog on the bad guy",
    "SINGE": "Burn, usually on the edges",
    "SM": "Small, samarium",
    "SN": "Tin",
    "SOH": "Fifth note of scale",
    "SOL": "Fifthnote in major scale, ancient roman god for sun",
    "SOT": "Habitual drunk",
    "SOU": "Small amount of money",
    "SR": "Senior, strontium",
    "ST": "Street, standard time",
    "STM": "Short term memory, scanning tunneling microscope",
    "STP": "Standard temperature and pressure",
    "SVR": "Rectified spirit of wine",
    "SW": "South west",
    "TA": "Tantalum",
    "TABOUR": "A small drum",
    "TAM": "Woolen cap",
    "TARPON": "A fish",
    "TAW": "Convert skin to leather with salts",
    "TB": "Terbium, tuberculosis, tablespoon",
    "TBA": "To be announced",
    "TBD": "To be determined",
    "TC": "Technetium",
    "TCP": "Transmission control protocol, data sent over internet",
    "TE": "Tellurium",
    "TED": "Spread out for drying",
    "TEG": "Two year old sheep",
    "TH": "Thorium",
    "THC": "Psychoactive chemical",
    "THZ": "Terahertz",
    "TI": "Titanium",
    "TIC": "Habitual muscle spasm",
    "TIRADE": "Angry rant or speech",
    "TKO": "Technical knockout",
    "TL": "Thallium",
    "TLC": "Tender love and care",
    "TM": "Thullium, trademark",
    "TN": "Tennessee",
    "TOCSIN": "Alarm bell",
    "TOD": "Unit of weight for wool",
    "TOG": "Dress in fine clothing",
    "TOM": "Male of various animals",
    "TOR": "High rocky hill",
    "TS": "Tennessine element 117",
    "TSA": "Transportation security administration",
    "TUM": "Sound of a plucked string",
    "TUN": "Large cask for wine",
    "TUP": "Heavy metal body",
    "TV": "Television",
    "TX": "Texas",
    "TY": "Thank you",
    "TYRO": "Beginner at something",
    "U": "Uranium",
    "UFO": "Unidentified flying object",
    "UHF": "Ultra high frequency",
    "UI": "User interface",
    "UK": "United kingdom",
    "UKE": "Ukulele",
    "ULNA": "Bone in little finger",
    "ULNAR": "Near the bone in the little finger",
    "UN": "United nations",
    "UNH": "Unnilhexium, or like uh-uh",
    "URD": "Asian legume, one of three greek gods for past present and future",
    "URL": "Uniform resource locator",
    "USA": "United states of america",
    "UT": "Utah",
    "UTC": "Universal time coordinated",
    "UTE": "Vehical for rough ground",
    "UV": "Ultra violet",
    "UX": "User experience",
    "UZI": "Submachine gun",
    "V": "Vanadium",
    "VA": "Virgina",
    "VAC": "Vacuum",
    "VAR": "Variable",
    "VAS": "Anatomical vessel",
    "VCR": "Video cassete recorder",
    "VHF": "Very high frequency",
    "VI": "Virgin islands",
    "VIM": "Robust energy and enthusiasm",
    "VIP": "Very important person",
    "VIRGA": "Wisps of evaporating precipitation",
    "VLF": "Very low frequency",
    "VOX": "Sound from vocal fold resonance with tract",
    "VR": "Virtual reality",
    "VS": "Versus",
    "VT": "Vermont",
    "W": "Tungstem",
    "WA": "Washington",
    "WAC": "Women us army unit during wwii",
    "WADER": "Tall waterproof boots, wading birds like herons",
    "WAN": "Wide area network",
    "WEN": "Abnormal growth or cyst",
    "WI": "Wisconsin",
    "WLAN": "Wireless local area network, your wifi",
    "WMD": "Weapons of mass destruction",
    "WOAD": "European herb",
    "WPM": "Words per minute",
    "WRY": "Bent or twisted",
    "WTC": "World trade center",
    "WTO": "World trade organization",
    "WV": "West virgina",
    "WY": "Wyoming",
    "XE": "Xeon",
    "XI": "Greek alphabet 14",
    "XL": "Extra large",
    "XMAS": "Christmas",
    "Y": "Yttrium",
    "YA": "Informal of you",
    "YAK": "Domesitcated ox",
    "YB": "Yterbium element 70",
    "YEN": "Japanese currency",
    "YEW": "Genus of evergreen tree",
    "YGDRASIL": "Norse mythology giant ash tree connecting universe",
    "YIN": "Yin and yang",
    "YMIR": "Norse mythology giant",
    "YOB": "Year of birth",
    "YON": "That or those at a distance",
    "YORUBA": "Language in area of nigeria",
    "YR": "Year",
    "ZAFTIG": "Full rounded figure",
    "ZEN": "Relax and not worry about unchangable things",
    "ZEP": "Large sandwich from crusty role",
    "ZIRCON": "Mineral",
    "ZITHER": "Stringed instrument",
    "ZN": "Zinc",
    "ZR": "Zirconium",
    "ZWIEBACK": "Cracker",
    "ZYDECO": "American dance music from southern lousiana"
}

let keys: Array<string> = []
for (const [k,_v] of Object.entries(shortDefinitions))
    keys.push(k);
keys.sort();

export const allKeys: Array<string> = keys;

export type wordDefState = {
    wordTyped: string,
    matchWordType: Array<string>,
    defnLookup: string
}

export function getDefinition(word: string, def: string): string {
    word = word.trim().toUpperCase();
    if(word in shortDefinitions)
        return shortDefinitions[word];
    return def;
}

export const initialWordDefState: wordDefState = {
    wordTyped: "ALPHIQUE",
    matchWordType: ["ALPHIQUE"],
    defnLookup: "ALPHIQUE"
}

export function findMatchingStrings(word: string): Array<string> {
    word = word.trim();
    word = word.toUpperCase();
    //word = word.slice(0,2);
    let ret: Array<string> = [];
    for(let i=0; i<allKeys.length; i++) {
        if (allKeys[i].includes(word)) {
            ret.push(allKeys[i]);
        }
    }
    return ret;
}

export const wordDefSlice = createSlice({
    name: "defs",
    initialState: initialWordDefState,
    reducers: {
        updateWordTyped: (state, action: PayloadAction<string>) => {
            state.wordTyped = action.payload;
            if(!!!action.payload)
                state.matchWordType = [];
            else
                state.matchWordType = findMatchingStrings(action.payload)
        },
        updateDefinitionWord: (state, action: PayloadAction<string>) => {
            state.defnLookup = action.payload;
        }
    }
})

export const {updateWordTyped, updateDefinitionWord} = wordDefSlice.actions;
export const wordDefReducer = wordDefSlice.reducer;