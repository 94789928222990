import { configureStore } from '@reduxjs/toolkit'
import { fullpageReducer } from '../features/fullpageSlice'
import { popoutReducer } from '../features/popout/popoutSlice'
import { wordDefReducer } from '../features/popout/wordDefsSlice'

export const store = configureStore({
  reducer: {
    fullpage: fullpageReducer,
    popout: popoutReducer,
    wordDef: wordDefReducer
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

/*
let timer = setInterval(() => {
  store.dispatch(updateTimer());
}, 1000);
store.dispatch(setTimerId(timer));
*/