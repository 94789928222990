import './menuStyle.css'
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { switchTab } from '../popoutSlice';
import { TutorialStep } from '../../tutorial/tutorialSlice';
import { nextTutorialState } from '../../fullpageSlice';

export type SwitchTabInput = {
    tab: "rules" | "hints" | "howtoplay" | "development" | "scoring" | "summary" | "history" | "defn",
    tutorialStep: TutorialStep
}

export function MenuSelect() {
    const menuSelection = useSelector((state: RootState) => state.popout.menuSelection);
    const tutorialStep = useSelector((state: RootState) => state.fullpage.tutorial.currentStep);
    const dispatch = useDispatch<AppDispatch>();

    let txtSummaryClasses = "";
    let txtRulesClasses="";
    let txtInstructionsClasses="";
    let txtHintsClasses="";
    let txtScoringClasses="";
    let txtHistoryClasses="";
    let txtDevClasses="";
    let txtDefnClasses=""

    let tabSummaryClasses = "popoutMenuItem";
    let tabRulesClasses="popoutMenuItem";
    let tabInstructionsClasses="popoutMenuItem";
    let tabHintsClasses="popoutMenuItem";
    let tabScoringClasses="popoutMenuItem";
    let tabHistoryClasses="popoutMenuItem";
    let tabDevClasses="popoutMenuItem";
    let tabDefnClasses="popoutMenuItem";

    switch(menuSelection) {
        case "development":
            tabDevClasses += " selectedTab";
            txtDevClasses="selectedText";
            break;
        case "hints":
            tabHintsClasses += " selectedTab";
            txtHintsClasses="selectedText";
            break;
        case "howtoplay":
            tabInstructionsClasses += " selectedTab";
            txtInstructionsClasses="selectedText";
            break;
        case "rules":
            tabRulesClasses += " selectedTab";
            txtRulesClasses="selectedText";
            break;
        case "scoring":
            tabScoringClasses += " selectedTab";
            txtScoringClasses="selectedText";
            break;
        case "summary":
            tabSummaryClasses += " selectedTab";
            txtSummaryClasses="selectedText";
            break;
        case "history":
            tabHistoryClasses += " selectedTab";
            txtHistoryClasses="selectedText";
            break;
        case "defn":
            tabDefnClasses += " selectedTab";
            txtDefnClasses="selectedText";
            break;
    }
    return (
        <div className="popoutMenu">
            <div className={tabSummaryClasses} onClick={(e) => {dispatch(switchTab({tab: "summary", tutorialStep: tutorialStep}))}}>
                <span className={txtSummaryClasses}>Summary</span>
            </div>
            <div className={tabRulesClasses} onClick={(e) => {dispatch(switchTab({tab: "rules", tutorialStep: tutorialStep}))}}>
                <span className={txtRulesClasses}>Rules</span>
            </div>
            <div className={tabInstructionsClasses} onClick={(e) => {dispatch(switchTab({tab: "howtoplay", tutorialStep: tutorialStep}))}}>
                <span className={txtInstructionsClasses}>Instructions</span>
            </div>
            <div className={tabHintsClasses} onClick={(e) => {dispatch(switchTab({tab: "hints", tutorialStep: tutorialStep}))}}>
                <span className={txtHintsClasses}>Hints</span>
            </div>
            <div className={tabScoringClasses} onClick={(e) => {dispatch(switchTab({tab: "scoring", tutorialStep: tutorialStep}))}}>
                <span className={txtScoringClasses}>Scoring</span>
            </div>
            <div className={tabHistoryClasses} onClick={(e) => {
                dispatch(switchTab({tab: "history", tutorialStep: tutorialStep}));
                if(tutorialStep === TutorialStep.TAB_TO_HISTORY)
                    dispatch(nextTutorialState());
                //console.log(historyState.length);
                //if(!!!historyState)
                //dispatch(loadHistoryOptions())
            }}>
                <span className={txtHistoryClasses}>History</span>
            </div>
            <div className={tabDefnClasses} onClick={(e) => {dispatch(switchTab({tab: "defn", tutorialStep: tutorialStep}))}}>
                <span className={txtDefnClasses}>Definitions</span>
            </div>
            <div className={tabDevClasses} onClick={(e) => {dispatch(switchTab({tab: "development", tutorialStep: tutorialStep}))}}>
                <span className={txtDevClasses}>Dev</span>
            </div>
        </div>

    )
}