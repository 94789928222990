import './popoutStyle.css'
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

export function HintsContent() {
    const menuSelection = useSelector((state: RootState) => state.popout.menuSelection);

    if (menuSelection !== "hints")
        return (<></>);

    return (
        <ul>
            <li>Click a word to specifically validate it</li>
            <li>Validates letters</li>
            <li>Validates words</li>
            <li>Reveals a letter</li>
            <li><b>Good:</b> Orange</li>
            <li><b>Bad:</b> Red</li>
            <li>Invalid words remember bad spots</li>
            <li>Invalid letters remember bad spots</li>
        </ul>
    )
}