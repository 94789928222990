import './userInputStyle.css';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { attemptAddWord } from "../fullpageSlice";
import { TypeButtons } from "./typeButtons/typeButtons";
import { Hints } from "./hints/hints";

export function UserInput() {
    const wordTyped = useSelector((state: RootState) => state.fullpage.wordTyped)
    const win = useSelector((state: RootState) => state.fullpage.win)
    const dispatch = useDispatch();

    if (win) return (<></>);    //show nothing on win

    let displayString = wordTyped.toUpperCase()
    let classDisplay = "wordTypedAlign"
    if(!!!displayString){
      classDisplay = "helpHint"
      displayString = "Recommendation:\n1) Type full word\n2) Tap desired location"
    }


    return (
        <div className="userInput">
            <TypeButtons />
            <div className="wordTypedHintGroup">
              <div className="wordTyped" onClick={(e) => {dispatch(attemptAddWord());}}>
                <p className={classDisplay}>{displayString}</p>
              </div>
              <Hints />
            </div>
        </div>
    )
}