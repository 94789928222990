import React from 'react';
import './index.css';
import App from './App';
import { createRoot } from 'react-dom/client'

import { store } from './app/store'
import { Provider } from 'react-redux'


const container = document.getElementById('app');
const root = createRoot(container!);
root.render(
    <Provider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>
)
