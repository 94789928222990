
export enum CellConditions {
    blocked = 0,
    blank = 1,
    lettersAvailable = 2,
    incorrect = 3,
    suggestBad = 4,
    suggested = 5,
    filled = 6, // don't know whether right or wrong
    selected = 7,
    hideSelected = 8,
    confirmed = 9,

}

export type CellLocation = {
    row: number;
    col: number;
}


type _CellState = {
    text: string | null;
    condition: CellConditions
}

export type CellState = _CellState & CellLocation;